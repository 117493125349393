import { Component, OnInit, OnDestroy, ViewChild, NgZone, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { AudioPlayerComponent } from '@clientApp-shared/components/audio-player/audio-player.component';
import { CommonService } from '@clientApp-core/services/common/common.service';
import { DeviceInfoService } from '@clientApp-core/services/device-info/deviceinfo.services';
import { MarketRateFacadeService } from '@clientApp-core/services/market/market-rates/market-rate-facade.service';
import { AuthFacadeService, websiteSettings } from '@clientApp-core/services/authentication/authentication-facade.service';
import { StoreService } from '@clientApp-core/services/store/store.service';
import { AudioType } from '@clientApp-core/enums/audio.types';
import { catchError, take } from 'rxjs/operators';
import { RulesComponent } from '@clientApp-shared/components/rules/rules.component';
import { NavigationEnd, Router } from '@angular/router';
import { ChangePasswordComponent } from 'app/features/change-password/change-password.component';
import { BetFacadeService } from '@clientApp-core/services/bet/bet.facade.service';
import { SearchComponent } from '@clientApp-shared/components/search/search.component';
import { SessionService } from '@clientApp-core/services/session/session.service';
import { NodeCentralService } from '@clientApp-core/services/session/node-central.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { throwError } from 'rxjs';
import * as M from "materialize-css/dist/js/materialize";
import { ConnectionState } from '@clientApp-core/enums/connectionState-type';
import { apiEndPointData } from '@clientApp-core/services/config/connfig.service';
import { MarketFacadeService } from '@clientApp-core/services/market/market-facade.service';
import { select, Store } from '@ngrx/store';
import { fancyRankOrder, GetSortOrder } from '@clientApp-core/utilities/app-configuration';
import { ScriptService } from '@clientApp-core/services/shared/script.service';
import { mapUniqueData } from '@clientApp-core/services/shared/JSfunction.service';
import { DepositWithdrawComponent } from 'app/features/deposit-withdraw/deposit-withdraw.component';
import { ActiveMarket } from '@clientApp-core/models/market/activemarket.model';
import { SharedModule } from '@clientApp-shared/shared.module';
import { LoadingBarComponent } from "../../../shared/components/loading-bar/loading-bar.component";
import { MaintenanceComponent } from "../../maintenance/maintenance.component";
import { HeaderComponent } from "../../../shared/components/header/header.component";
declare var $: any;

@Component({
    selector: 'app-home-layout',
    standalone: true,
    templateUrl: './home-layout.component.html',
    styleUrls: ['./home-layout.component.scss'],
    imports: [SharedModule, AudioPlayerComponent, LoadingBarComponent, RulesComponent, ChangePasswordComponent, SearchComponent, DepositWithdrawComponent, MaintenanceComponent, HeaderComponent]
})
export class HomeLayoutComponent implements OnInit,AfterViewInit, OnDestroy {
  loading: boolean;
  interval;
  user: any;
  private previousUrl: string;
  private currentUrl: string;
  selectedTab = 'Home';
  isMenuOpen = false;
  allMarkets: any = [];
  bgImageVariable = '';
  isDarkTheme: boolean = false;
  isB2C: boolean;
  inMaintenance: boolean;
  isCheckedDarkTheme: boolean = true;
  bottomtabstoggleClass: boolean = false;
  @ViewChild('player', { static: true }) audioPlayerRef: AudioPlayerComponent;
  @ViewChild('rules', { static: true }) rulesRef: RulesComponent;
  @ViewChild('changePass', { static: true }) changePassRef: ChangePasswordComponent;
  @ViewChild('search', { static: true }) searchRef: SearchComponent;
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('bottomMenu') bottomMenu: ElementRef;
  @ViewChild('toggleImg') toggleImg: ElementRef;
  @ViewChild('toggleSpan') toggleSpan: ElementRef;
  @ViewChild('depositwithdraw', { static: true }) depositwithdrawRef: DepositWithdrawComponent;
  constructor(private router: Router, public commonService: CommonService,
    private ngZone: NgZone,
    private deviceInfoService: DeviceInfoService,
    private marketRateService: MarketRateFacadeService,
    private authService: AuthFacadeService,
    private storeService: StoreService,
    private sessionService: SessionService,
    private nodeCentralService: NodeCentralService,
    private renderer: Renderer2,
    private marketFacadeService: MarketFacadeService, private scriptService: ScriptService) {
    this.commonService.getLoadingStatus().subscribe(status => {
      this.loading = status;
    });
    this.marketRateService.getAudioType$().subscribe((type: AudioType) => {
      this.audioPlayerRef.playAudio(type);
    });
    const self = this;
    this.currentUrl = this.router.url;
    this.previousUrl = null;
    this.router.events.subscribe(val => {
        if (
          val instanceof NavigationEnd) {
            this.previousUrl = this.currentUrl;
            this.currentUrl = val.urlAfterRedirects;
            if (this.currentUrl == '/account-statement' || this.currentUrl == '/old-account-statement' || this.currentUrl == '/results' ||
              this.currentUrl == '/profit-and-loss' || this.currentUrl == '/my-bets') {
              this.loadScript();
            }
        }
    });
    this.renderer.listen('window', 'click',(e:Event)=>{
    
      if (e.target !== this.toggleButton.nativeElement &&
        e.target !== this.bottomMenu?.nativeElement &&
        e.target !== this.toggleImg?.nativeElement &&
        e.target !== this.toggleSpan?.nativeElement) {
         this.isMenuOpen = false;
         this.selectedTab = '';
       }
    });
    this.commonService.getDarkThemeStatus().subscribe(isChecked => {
      this.isDarkTheme = isChecked;
      this.checkIsDarkThemeExists();
    });
  }

  ngOnInit() {
    this.inMaintenance = apiEndPointData.data.inMaintenance;
    // this.isB2C = apiEndPointData.data.isB2C;
    this.isB2C = websiteSettings.data.isB2C;
    this.bgImageVariable = apiEndPointData.data.dashboardBgImage ? apiEndPointData.data.themeBasePath + apiEndPointData.data.dashboardBgImage : apiEndPointData.data.themeBasePath + 'assets/images/background-img.png';
    const darkThemeCurrentState = this.commonService.getCookieValue('isCheckedDarkTheme');
    this.isDarkTheme = (darkThemeCurrentState != null && darkThemeCurrentState != undefined && darkThemeCurrentState != '' && JSON.parse(darkThemeCurrentState).isCheckedDarkTheme) ? true : false;
    this.checkIsDarkThemeExists();
    this.user = JSON.parse(localStorage.getItem('token'));
    // if(window.location.hash != '#/market') { this.authService.getConfig(); }
    this.getConfig();
    this.authService.getConfig(); 
    // this.authService.getCasinoConfig();
    this.checkSession();
    this.authService.getCasinoConfig();
  }
  ngAfterViewInit() {
  }
  bottomTabsclickEvent(){
    this.bottomtabstoggleClass = !this.bottomtabstoggleClass;       
}
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    if (this.isMenuOpen) {
      this.selectedTab = 'account';
    } else {
      this.selectedTab = ' ';
    }
      
  }
  onChangeDarkTheme(isChecked: boolean) {
    document.body.classList.toggle('dark');
    if (isChecked) {
        this.isCheckedDarkTheme = true;
        this.commonService.setCookieValue('isCheckedDarkTheme', JSON.stringify({ isCheckedDarkTheme: true }));
        this.commonService.setDarkThemeStatus(true);
    } else {
        this.isCheckedDarkTheme = false;
        this.commonService.setCookieValue('isCheckedDarkTheme', JSON.stringify({ isCheckedDarkTheme: false }));
        this.commonService.setDarkThemeStatus(false);
    }
  }
  getConfig() {
    this.authService.getConfig$()
      .pipe(
        untilDestroyed(this), take(1),
        catchError(err => throwError(err))
      ).subscribe(response => {
        if (response) {
          this.commonService.configData = response;
          this.isB2C = websiteSettings.data.isB2C;
          const output = '1';
          const getVeronicaPopupstatus = localStorage.getItem('showVeronicaPopup');
          if (websiteSettings.data.isShowCasinoGamePopup && getVeronicaPopupstatus !== output) {
            // this.veronicaRef.openPopup();
          }
        }
      }, err => console.log('getConfig', err));
  }
  checkSession() {
    const user = JSON.parse(localStorage.getItem('token'));
    if (user != null) {
      this.getExpTime(user.expireOn);
    }
  }
  onSwipe(evt) {
    if (this.deviceInfoService.isMobile()) {
      const x = Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? 'right' : 'left') : '';
      const y = Math.abs(evt.deltaY) > 40 ? (evt.deltaY > 0 ? 'down' : 'up') : '';
      if (x === 'left') {
        $('.nav-tabs a.active').next('a').tab('show');
      } else if (x === 'right') {
        $('.nav-tabs a.active').prev('a').tab('show');
      }
      // console.log(`${x} ${y}`);
    }
  }
  getExpTime(strdate) {
    const now = new Date(); //  GMT+0530 (India Standard Time)
    const isoDate = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
    const today: any = new Date(isoDate);
    const expDate: any = new Date(strdate);
    const diffMs = (expDate - today); // milliseconds between now & Christmas
    const diffDays = Math.floor(diffMs / 86400000); // days
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

    let callAgain = false;
    if (diffDays <= 0) {
      if (diffHrs <= 0) {
        if (diffMins <= 4) {
          clearTimeout(this.interval);
          this.getNewToken();
        } else {
          callAgain = true;
        }
      } else {
        callAgain = true;
      }
    } else {
      callAgain = true;
    }

    if (callAgain) {
      this.ngZone.runOutsideAngular(() => {
        this.interval = window.setTimeout(() => {
          this.getExpTime(strdate);
        }, 60000);
      });
    }
    // console.log('H:', diffHrs, 'M:', diffMins);
  }
  getNewToken() {
    this.authService.IsAuthorized$().pipe(take(1)).subscribe((data: any) => {
      if (data.isAuthorized) {
        if (data.isNewToken) {
          const user = JSON.parse(localStorage.getItem('token'));
          if (user != null) {
            user.access_token = data.token;
            user.expireOn = data.expireOn;
            localStorage.setItem('token', JSON.stringify(user));
            this.checkSession();
          }
        }
      } else {
        // logout
        this.storeService.clearStore();
      }
    });
  }
  // for mobile view bottom tab
  onMenuClick(selectedTab: string) {
    this.commonService.setCasinoOpenStatus(true);
    this.commonService.selectedProviderStatus(true);
    this.commonService.allgamesselected(true);
    if (this.currentUrl !== '/live-casino') {
      this.router.navigateByUrl('/live-casino');
    }
  }
  redirectTo(path) {
    if (websiteSettings.data.isReportPageOpenInNewTab) {
      let newRelativeUrl = this.router.createUrlTree([path]);
      let baseUrl = window.location.href.replace(this.router.url, '');
      window.open(baseUrl + newRelativeUrl, '_blank');
    } else {
    this.commonService.setCasinoOpenStatus(true);
    this.commonService.selectedProviderStatus(true);
    this.commonService.allgamesselected(true);
    this.router.navigateByUrl(path);
    }
  }
  onTcClick() {
    this.rulesRef.openPopup();
  }
  onChangePassClick() {
    this.changePassRef.openPopup();
  }
  onLogOut() {
    this.authService.LogOut$();
    this.storeService.clearStore();
    // this.commonService.FavoriteIds = [];
  }
  openSearchModal() {
    this.searchRef.openPopup();
  }
  searchValueChange(val) {
  }
  onCloseBtnClick() {
    this.isMenuOpen = false;
    this.selectedTab = ' ';
  }
  checkIsDarkThemeExists() {
    if (this.isDarkTheme) {
      this.bgImageVariable = apiEndPointData.data.darkDashboardBgImage ? apiEndPointData.data.themeBasePath + apiEndPointData.data.darkDashboardBgImage : apiEndPointData.data.themeBasePath + 'assets/images/background-img-dark.png';
    } else {
      this.bgImageVariable = apiEndPointData.data.dashboardBgImage ? apiEndPointData.data.themeBasePath + apiEndPointData.data.dashboardBgImage : apiEndPointData.data.themeBasePath + 'assets/images/background-img.png';
    }
  }
  loadScript() {
    this.scriptService.load('external').then(data => {
      // console.log('script loaded');
  }).catch(error => console.log('loadScript',error));
  }
  onDepositWithdrawClick() {
    this.depositwithdrawRef.openPopup();
}
  ngOnDestroy() {
    window.clearTimeout(this.interval);
  }
}

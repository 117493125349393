export const AppMessage = {
  serviceUnavailable: 'Service is temporarily unavailable, please try again later'
};
export const fancyRankOrder = new Map([
  [0, 1],  // matchOdd
  [7, 2],  // bookMaker
  [10, 3], // manualOdds
  [9, 4],  // lineMarket
  [6, 5]   // adv.session
]);
export const sportRankOrder = new Map([
  ['Cricket', 1],  // cricket
]);
export const excludeSportRankOrder = new Map([
  ['Horse Racing', 1],  // cricket
]);
export function GetSortOrder(prop) {

  return function (a, b) {
    let key1 = a[prop];
    let key2 = b[prop];
    if (prop === 'appDate') {
      key1 = new Date(a[prop]);
      key2 = new Date(b[prop]);
    } 
    if (key1 > key2) {
      return 1;
    } else if (key1 < key2) {
      return -1;
    }
    return 0;
  };
}
export function GetsortDescOreder(prop) {
  return function (a, b) {
    let key1 = a[prop];
    let key2 = b[prop];
    if (prop === 'appDate') {
      key1 = new Date(a[prop]);
      key2 = new Date(b[prop]);
    } 
    if (key2 > key1) {
      return 1;
    } else  {
      return -1;
    }
  };
}

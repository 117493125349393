import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseRequest } from '@clientApp-core/models/base/base-request.model';
import { guid } from '@clientApp-core/utilities/app-util';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { apiEndPointData } from '../config/connfig.service';

export class BonusRequest implements BaseRequest<any> {
  constructor(public body: any, public queryParameter: any, public endPoint: string, public baseUrl: string = null) { }
}
export function getCampaignListRequest(): BaseRequest<any> {
    const request = new BonusRequest({}, {}, 'api/Campaign/GetCampaignList');
    return request;
}

export function getWalletListRequest(): BaseRequest<any> {
    const request = new BonusRequest({}, {}, 'api/Campaign/GetWalletList');
    return request;
}

export function updateWalletRequest(WalletId: any): BaseRequest<any> {
    const payload = Object.assign({WalletId});
    const request = new BonusRequest(payload, {}, 'api/Campaign/UpdateWallet');
    return request;
}

@Injectable({
  providedIn: 'root'
})
export class BonusService {

  constructor(private httpClient: HttpClient) { }

  getCampaignList() {
    const getCampaignRequest = getCampaignListRequest();
    return this.httpClient.post(this.getBaseUrl(getCampaignRequest), getCampaignRequest.body, { params: getCampaignRequest.queryParameter })
    .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
  }
  getWalletList() {
    const getWalletRequest = getWalletListRequest();
    return this.httpClient.post(this.getBaseUrl(getWalletRequest), getWalletRequest.body, { params: getWalletRequest.queryParameter })
    .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
  }
  updateWalletRequest(WalletId: any): Observable<any> {
    const getupdateWalletRequest = updateWalletRequest(WalletId);
    return this.httpClient.post(this.getBaseUrl(getupdateWalletRequest), getupdateWalletRequest.body, { params: getupdateWalletRequest.queryParameter })
    .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
  }
  getBaseUrl(item: any): string {
    let baseUrl: string;
    if (item.baseUrl) {
      baseUrl = `${item.baseUrl}${item.endPoint}`;
    } else {
      baseUrl = `${apiEndPointData.data.webProdMarketApiUrl}${item.endPoint}`;
    }
    return baseUrl;
  }
}

import { Component, OnInit, OnDestroy, ViewChild, Input, ElementRef, AfterViewInit } from '@angular/core';
import { SharedModule } from '@clientApp-shared/shared.module';
import { Store, select } from '@ngrx/store';
import * as M from "materialize-css/dist/js/materialize";

@Component({
  selector: 'pb-rules',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.css']
})
export class RulesComponent implements  OnInit, AfterViewInit,  OnDestroy {
  @ViewChild('rules', { static: true }) template: ElementRef;
  rulesModalInstances:any;
  @Input() name: string;
  marketRules: string;

  constructor() { }

  ngOnInit() {
    const user = JSON.parse(localStorage.getItem('token'));
    if (user != null) {
      this.marketRules = user.terms;
    }
  }
  ngAfterViewInit() {
    this.rulesModalInstances = M.Modal.init(this.template.nativeElement, {});
  }
  openPopup() {
    this.rulesModalInstances.open();
  }
  hideModal(){
    this.rulesModalInstances.close();
  }
  ngOnDestroy() {}
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseRequest } from '@clientApp-core/models/base/base-request.model';
import { guid } from '@clientApp-core/utilities/app-util';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { apiEndPointData } from '../config/connfig.service';

export class B2cUserRequest implements BaseRequest<any> {
  constructor(public body: any, public queryParameter: any, public endPoint: string, public baseUrl: string = null) { }
}

export function mapAddNewClientRequest(payload: any): BaseRequest<any> {
  const request = new B2cUserRequest(payload, {}, 'api/B2CUser/AddNewClient');
  return request;
}
export function mapVerifyUserInfoRequest(text: string, type: number): BaseRequest<any> {
  const payload = Object.assign({text, type});
  const request = new B2cUserRequest(payload, {}, 'api/B2CUser/ValidateInfo');
  return request;
}
export function mapregistrationRequest(Username: string, EmailID: string, MobileNo: any, Password: string, ConfirmPassword: string): BaseRequest<any> {
  const payload = Object.assign({Username, EmailID, MobileNo, Password, ConfirmPassword});
  const request = new B2cUserRequest(payload, {}, 'api/B2CUser/Registration');
  return request;
}
export function mapForgotPasswordRequest(mobileNo: any): BaseRequest<any> {
  const payload = Object.assign({mobileNo});
  const request = new B2cUserRequest(payload, {}, 'api/B2CUser/ForgotPasswordRequest');
  return request;
}
export function mapResentOTPRequest(mobileNo: any): BaseRequest<any> {
  const payload = Object.assign({mobileNo});
  const request = new B2cUserRequest(payload, {}, 'api/B2CUser/SendOTPDeposit');
  return request;
}
export function mapDepositResentOTPRequest(mobileNo: any): BaseRequest<any> {
  const payload = Object.assign({mobileNo});
  const request = new B2cUserRequest(payload, {}, 'api/B2CUser/ResendOTPDeposit');
  return request;
}

export function mapVerifyResentOTPRequest(mobileNo: any, OTP: any): BaseRequest<any> {
  const payload = Object.assign({mobileNo, OTP});
  const request = new B2cUserRequest(payload, {}, 'api/B2CUser/VerifyOTPDeposit');
  return request;
}

export function mapGetKycStatusRequest(): BaseRequest<any> {
  const request = new B2cUserRequest({}, {}, 'api/B2CUser/GetKycStatus');
  return request;
}
export function mapEmailVerificationRequest(): BaseRequest<any> {
  const request = new B2cUserRequest({}, {}, 'api/B2CUser/EmailVerification');
  return request;
}
export function mapReVerifyEmailRequest(): BaseRequest<any> {
  const request = new B2cUserRequest({}, {}, 'api/B2CUser/ReVerifyEmail');
  return request;
}
export function mapGetKycDocumentRequest(): BaseRequest<any> {
  const request = new B2cUserRequest({}, {}, 'api/B2CUser/GetKycDocument');
  return request;
}
export function mapGetDWRequest(): BaseRequest<any> {
  const request = new B2cUserRequest({}, {}, 'api/B2CUser/GetDWLimit');
  return request;
}
export function mapUpdateKycRequest(payload): BaseRequest<any> {
  const request = new B2cUserRequest(payload, {}, 'api/B2CUser/UpdateKyc');
  return request;
}
export function mapDepositRequest(payload): BaseRequest<any> {
  const body = Object.assign(payload, { transactionId: guid() })
  const request = new B2cUserRequest(body, {}, 'api/B2CUser/Deposit');
  return request;
}
export function mapWithdrawRequest(payload): BaseRequest<any> {
  const body = Object.assign(payload, { transactionId: guid() })
  const request = new B2cUserRequest(body, {}, 'api/B2CUser/Withdraw');
  return request;
}
export function mapGetDepositeWithdrawReportRequest(payload): BaseRequest<any> {
  const request = new B2cUserRequest(payload, {}, 'api/B2CUser/GetDepositeWithdrawReport');
  return request;
}
export function mapGetPaymentStatusDropDownRequest(): BaseRequest<any> {
  const request = new B2cUserRequest({}, {}, 'api/B2CUser/GetPaymentStatusDropDown');
  return request;
}
export function mapVerifyOTPRequest(payload): BaseRequest<any> {
  const request = new B2cUserRequest(payload, {}, 'api/B2CUser/VerifyOTP');
  return request;
}
// export function mapRegistrationRequest(Username: string, EmailID: string, MobileNo: any, OTP: any, Password: string, ConfirmPassword: string): BaseRequest<any> {
//   const payload = Object.assign({Username, EmailID, MobileNo, OTP, Password, ConfirmPassword});
//   const request = new B2cUserRequest(payload, {}, 'api/B2CUser/Registration');
//   return request;
// }
export function mapForgotPasswordSubmitRequest(otp: any, mobileNo: any, password: string, confirmPassword: string): BaseRequest<any> {
  const payload = Object.assign({otp, mobileNo, password, confirmPassword});
  const request = new B2cUserRequest(payload, {}, 'api/B2CUser/ForgotResetPassword');
  return request;
}

export function mapResendOTPRequest(mobileNo: any): BaseRequest<any> {
  const payload = Object.assign({mobileNo});
  const request = new B2cUserRequest(payload, {}, 'api/B2CUser/ResendOTPSignUp');
  return request;
}
export function mapfpResendOTPRequest(mobileNo: any): BaseRequest<any> {
  const payload = Object.assign({mobileNo});
  const request = new B2cUserRequest(payload, {}, 'api/B2CUser/ResendOTPResetPassword');
  return request;
}
export function mapAddBankDetailRequest(payload): BaseRequest<any> {
  const request = new B2cUserRequest(payload, {}, 'api/B2CUser/AddBankDetail');
  return request;
}
export function mapGetBankDetailRequest(): BaseRequest<any> {
  const request = new B2cUserRequest({}, {}, 'api/B2CUser/GetBankDetail');
  return request;
}
export function mapGetBankListDetailRequest(): BaseRequest<any> {
  const request = new B2cUserRequest({}, {}, 'api/B2CUser/GetBankList');
  return request;
}
export function mapTransactionHistoryRequest(transactionId: any): BaseRequest<any> {
  const payload = Object.assign({ transactionID: transactionId });
  const request = new B2cUserRequest(payload, {}, 'api/B2CUser/GetWithdrawLogData');
  return request;
}
@Injectable({
  providedIn: 'root'
})
export class B2cUserService {

  constructor(private httpClient: HttpClient) { }

  AddNewClient(payload: any): Observable<any> {
    const AddNewClientRequest = mapAddNewClientRequest(payload);
    return this.httpClient.post(this.getBaseUrl(AddNewClientRequest), AddNewClientRequest.body, { params: AddNewClientRequest.queryParameter })
    .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
  }
  VerifyDetails(text: string, type: number): Observable<any> {
    const UserVerifyDetails = mapVerifyUserInfoRequest(text, type);
    return this.httpClient.post(this.getBaseUrl(UserVerifyDetails), UserVerifyDetails.body, { params: UserVerifyDetails.queryParameter })
    .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
  }
  RegisterInfo(Username: string, EmailID: string, MobileNo: any, Password: string, ConfirmPassword: string): Observable<any> {
    const RegisterInfoRequest = mapregistrationRequest(Username, EmailID, MobileNo, Password, ConfirmPassword);
    return this.httpClient.post(this.getBaseUrl(RegisterInfoRequest), RegisterInfoRequest.body, { params: RegisterInfoRequest.queryParameter })
    .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
  }
  ForgotPassword(mobileno: any): Observable<any> {
    const ForgotPasswordRequest = mapForgotPasswordRequest(mobileno);
    return this.httpClient.post(this.getBaseUrl(ForgotPasswordRequest), ForgotPasswordRequest.body, { params: ForgotPasswordRequest.queryParameter })
    .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
  }
  DPResendOTPRequest(mobileNo: any): Observable<any> {
    const dpResendOTPRequest = mapResentOTPRequest(mobileNo);
    return this.httpClient.post(this.getBaseUrl(dpResendOTPRequest), dpResendOTPRequest.body, { params: dpResendOTPRequest.queryParameter })
    .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
  }
  DepositResendOTPRequest(mobileNo: any): Observable<any> {
    const depositResendOTPRequest = mapDepositResentOTPRequest(mobileNo);
    return this.httpClient.post(this.getBaseUrl(depositResendOTPRequest), depositResendOTPRequest.body, { params: depositResendOTPRequest.queryParameter })
    .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
  }
  VerifyResendOTPRequest(mobileNo: any, OTP: any): Observable<any> {
    const VerifyResendOTPRequest = mapVerifyResentOTPRequest(mobileNo, OTP);
    return this.httpClient.post(this.getBaseUrl(VerifyResendOTPRequest), VerifyResendOTPRequest.body, { params: VerifyResendOTPRequest.queryParameter })
    .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
  }
  GetKycStatus() {
    const getKycStatusRequest = mapGetKycStatusRequest();
    return this.httpClient.post(this.getBaseUrl(getKycStatusRequest), getKycStatusRequest.body, { params: getKycStatusRequest.queryParameter })
    .pipe(map((data: any) => { return data.result }), catchError(err => { return throwError(err) }));
  }
  EmailVerification(qtoken) {
    const emailVerificationRequest = mapEmailVerificationRequest();
    return this.httpClient.get(this.getBaseUrl(emailVerificationRequest), {
      params: {
        token: qtoken
    }}).pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
  }
  ReEmailVerification(qtoken) {
    const reVerifyEmailRequest = mapReVerifyEmailRequest();
    return this.httpClient.get(this.getBaseUrl(reVerifyEmailRequest), {
      params: {
        token: qtoken
    }}).pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
  }
  GetKycDocument() {
    const getKycDocumentRequest = mapGetKycDocumentRequest();
    return this.httpClient.post(this.getBaseUrl(getKycDocumentRequest), getKycDocumentRequest.body, { params: getKycDocumentRequest.queryParameter })
    .pipe(map((data: any) => { return data.result }), catchError(err => { return throwError(err) }));
  }
  GetDWLimitResult() {
    const getDWRequest = mapGetDWRequest();
    return this.httpClient.post(this.getBaseUrl(getDWRequest), getDWRequest.body, { params: getDWRequest.queryParameter })
    .pipe(map((data: any) => { return data.result }), catchError(err => { return throwError(err) }));
  }
  UpdateKycDocument(payload) {
    const updateKycRequest = mapUpdateKycRequest(payload);
    return this.httpClient.post(this.getBaseUrl(updateKycRequest), updateKycRequest.body, { params: updateKycRequest.queryParameter })
    .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
  }
  depositAmount(payload) {
    const depositRequest = mapDepositRequest(payload);
    return this.httpClient.post(this.getBaseUrl(depositRequest), depositRequest.body, { params: depositRequest.queryParameter })
    .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
  }
  WithdrawRequest(payload) {
    const withdrawRequest = mapWithdrawRequest(payload);
    return this.httpClient.post(this.getBaseUrl(withdrawRequest), withdrawRequest.body, { params: withdrawRequest.queryParameter })
    .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
  }
  GetPaymentStatusDropDown() {
    const getPaymentStatusDropDownRequest = mapGetPaymentStatusDropDownRequest();
    return this.httpClient.post(this.getBaseUrl(getPaymentStatusDropDownRequest), getPaymentStatusDropDownRequest.body, { params: getPaymentStatusDropDownRequest.queryParameter })
    .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
  }
  GetDepositeWithdrawReport(payload) {
    const getDepositeWithdrawReport = mapGetDepositeWithdrawReportRequest(payload);
    return this.httpClient.post(this.getBaseUrl(getDepositeWithdrawReport), getDepositeWithdrawReport.body, { params: getDepositeWithdrawReport.queryParameter })
    .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
  }
  verifyOTPRequest(payload) {
    const verifyOTPRequest = mapVerifyOTPRequest(payload);
    return this.httpClient.post(this.getBaseUrl(verifyOTPRequest), verifyOTPRequest.body, { params: verifyOTPRequest.queryParameter })
    .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
  }
  // verifyRegistrationRequest(Username: string, EmailID: string, MobileNo: any, OTP: any, Password: string, ConfirmPassword: string) {
  //   const verifyRegisterRequest = mapRegistrationRequest(Username, EmailID, MobileNo, OTP, Password, ConfirmPassword);
  //   return this.httpClient.post(this.getBaseUrl(verifyRegisterRequest), verifyRegisterRequest.body, { params: verifyRegisterRequest.queryParameter })
  //   .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
  // }
  verifyForgotPasswordRequest(otp: any, mobileNo: any, password: string, confirmPassword: string) {
    const verifyForgotPasswordRequest = mapForgotPasswordSubmitRequest(otp, mobileNo, password, confirmPassword);
    return this.httpClient.post(this.getBaseUrl(verifyForgotPasswordRequest), verifyForgotPasswordRequest.body, { params: verifyForgotPasswordRequest.queryParameter })
    .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
  }
  resendOTPRequest(number: any) {
    const resendOTPRequest = mapResendOTPRequest(number);
    return this.httpClient.post(this.getBaseUrl(resendOTPRequest), resendOTPRequest.body, { params: resendOTPRequest.queryParameter })
    .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
  }
  fpresendOTPRequest(number: any) {
    const fpresendOTPRequest = mapfpResendOTPRequest(number);
    return this.httpClient.post(this.getBaseUrl(fpresendOTPRequest), fpresendOTPRequest.body, { params: fpresendOTPRequest.queryParameter })
    .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
  }
  GetBankDetail() {
    const getBankDetailRequest = mapGetBankDetailRequest();
    return this.httpClient.get(this.getBaseUrl(getBankDetailRequest), getBankDetailRequest.body)
    .pipe(map((data: any) => { return data.result }), catchError(err => { return throwError(err) }));
  }
  GetBankListDetail() {
    const getBankListDetailRequest = mapGetBankListDetailRequest();
    return this.httpClient.post(this.getBaseUrl(getBankListDetailRequest), getBankListDetailRequest.body)
    .pipe(map((data: any) => { return data.result }), catchError(err => { return throwError(err) }));
  }
  AddBankDetail(payload) {
    const addBankDetailRequest = mapAddBankDetailRequest(payload);
    return this.httpClient.post(this.getBaseUrl(addBankDetailRequest), addBankDetailRequest.body, { params: addBankDetailRequest.queryParameter })
    .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
  }
  getTransactionHistory$(transactionId: any): Observable<any> {
    const transactionHistoryRequest = mapTransactionHistoryRequest(transactionId);
    return this.httpClient
        .post(this.getBaseUrl(transactionHistoryRequest), transactionHistoryRequest.body, { params: transactionHistoryRequest.queryParameter})
        .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
}
  getBaseUrl(item: any): string {
    let baseUrl: string;
    if (item.baseUrl) {
      baseUrl = `${item.baseUrl}${item.endPoint}`;
    } else {
      baseUrl = `${apiEndPointData.data.cau}${item.endPoint}`;
    }
    return baseUrl;
  }
}

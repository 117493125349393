import { Injectable } from '@angular/core';
import { Observable, throwError, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { apiEndPointData } from '../config/connfig.service';
import { BaseRequest } from '@clientApp-core/models/base/base-request.model';

export class ResultsRequest implements BaseRequest<any> {
    constructor(public body: any, public queryParameter: any, public endPoint: string, public baseUrl: string = null) {}
}
export function mapSportsRequest(): BaseRequest<any> {
    const request = new ResultsRequest({}, {}, mapSportsEndpoint(''));
    return request;
}
export function mapTournamentsRequest(sportID: number): BaseRequest<any> {
    const request = new ResultsRequest({}, { sportID: sportID }, mapTournamentEndpoint(''));
    return request;
}
export function mapMatchsRequest(tournamentID: number): BaseRequest<any> {
    const request = new ResultsRequest({}, { tournamentID: tournamentID }, mapMatchEndpoint(''));
    return request;
}
export function mapMarketsRequest(matchID: number): BaseRequest<any> {
    const request = new ResultsRequest({}, { matchID: matchID }, mapMarketEndpoint(''));
    return request;
}
export function mapMatchResultRequest(sportID: any, tournamentID: any, matchID: any,
     betID: any, fancyType: string, fromDate: Date, toDate: Date): BaseRequest<any> {
    const body = {
                    matchID: matchID,
                    sportID: sportID,
                    tournamentID: tournamentID,
                    betID: betID,
                    fancyType: fancyType,
                    fromDate: fromDate,
                    toDate: toDate
                };
    const request = new ResultsRequest(body, {}, mapMatchResultEndpoint(''));
    return request;
}
export function mapSportsEndpoint(endPoint: any): string {
    const endPointUrl = 'api/Result/SportsList';
    return endPointUrl;
}
export function mapTournamentEndpoint(endPoint: any): string {
    const endPointUrl = 'api/Result/TournamentList';
    return endPointUrl;
}
export function mapMatchEndpoint(endPoint: any): string {
    const endPointUrl = 'api/Result/MatchList';
    return endPointUrl;
}
export function mapMarketEndpoint(endPoint: any): string {
    const endPointUrl = 'api/Result/BetList';
    return endPointUrl;
}
export function mapMatchResultEndpoint(endPoint: any): string {
    const endPointUrl = 'api/Result/MatchResult';
    return endPointUrl;
}

@Injectable()
export class ResultsFacadeService {

    private _matchResultSubject = new Subject<any>();

    constructor(private httpClient: HttpClient) { }

    getSportsList$(): Observable<any> {
        const sportsRequest = mapSportsRequest();
        return this.httpClient.get(this.getBaseUrl(sportsRequest), { params: sportsRequest.queryParameter })
            .pipe(map((data: any) => { return data.result }), catchError(err => { return throwError(err) }));
    }
    getTournamentList$(sportID: number): Observable<any[]> {
        const tournamentRequest = mapTournamentsRequest(sportID);
        return this.httpClient.get(this.getBaseUrl(tournamentRequest), { params: tournamentRequest.queryParameter })
            .pipe(map((data: any) => { return data.result }), catchError(err => { return throwError(err) }));
    }
    getMatchList$(tournamentID: number): Observable<any[]> {
        const matchRequest = mapMatchsRequest(tournamentID);
        return this.httpClient.get(this.getBaseUrl(matchRequest), { params: matchRequest.queryParameter })
            .pipe(map((data: any) => { return data.result }), catchError(err => { return throwError(err) }));
    }
    getMarketList$(matchID: number): Observable<any[]> {
        const marketRequest = mapMarketsRequest(matchID);
        return this.httpClient.get(this.getBaseUrl(marketRequest), { params: marketRequest.queryParameter })
            .pipe(map((data: any) => { return data.result }), catchError(err => { return throwError(err) }));
    }
    getMatchResult(sportID: any, tournamentID: any, matchID: any,
        betID: any, fancyType: string, fromDate: Date, toDate: Date): Observable<any> {
        const matchResultRequest = mapMatchResultRequest(sportID,
            tournamentID, matchID, betID, fancyType, fromDate, toDate);
        return  this.httpClient.post(this.getBaseUrl(matchResultRequest), matchResultRequest.body, { params: matchResultRequest.queryParameter })
            .pipe(map((data: any) => { return data.result }), catchError(err => { return throwError(err) }));
            // .subscribe((results) => this._matchResultSubject.next(results), err => console.log('getMatchResult', err));
    }
    getMatchResult$(): Observable<any> {
        return this._matchResultSubject.asObservable();
    }
    getBaseUrl(item: any): string {
        let baseUrl: string;
        if (item.baseUrl) {
            baseUrl = `${item.baseUrl}${item.endPoint}`;
        } else {
            baseUrl = `${apiEndPointData.data.cau}${item.endPoint}`;
        }
        return baseUrl;
    }
}




import { Injectable } from '@angular/core';
import { Observable, throwError, Subject } from 'rxjs';
import {
  mapLoginRequest, mapIsAuthorizedRequest,
  mapConfigRequest,
  mapLogoutRequest,
  CaptchaCodeRequest,
  mapCasinoConfigRequest,
  mapGetGuestMLConfigEndPointRequest,
  mapGetGuestCasinoConfigEndPointRequest,
  mapFavoriteGamesRequest,
  mapAddFavoriteGameRequest,
  mapRemoveFavoriteGameRequest
} from '@clientApp-core/services/authentication/authentication-helper.service';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { apiEndPointData } from '../config/connfig.service';


@Injectable()
export class AuthFacadeService {
  private _configurationSubject = new Subject<any>();
  private _casinoConfigurationSubject = new Subject<any>();
  private _GuestCasinoConfigurationSubject = new Subject<any>();
  private _ResetPasswordSubject = new Subject<boolean>();
  constructor(private httpClient: HttpClient) { }

  LogIn$(name: string, password: string, Captcha: string, RequestId: string): Observable<any> {
    const logInRequest = mapLoginRequest(name, password, Captcha, RequestId);
    return this.httpClient.post(this.getBaseUrl(logInRequest), logInRequest.body, { params: logInRequest.queryParameter })
      .pipe(map((data: any) => { return data.result }), catchError(err => { return throwError(err) }));
  }
  LogOut$() {
    const logOutRequest = mapLogoutRequest();
    this.httpClient.get(this.getBaseUrl(logOutRequest), logOutRequest.body).pipe(catchError(err => throwError(err)))
      .subscribe((response) => { }, err => console.log('LogOut', err));
  }
  IsAuthorized$() {
    const isAuthorizedRequest = mapIsAuthorizedRequest();
    return this.httpClient.get(this.getBaseUrl(isAuthorizedRequest), isAuthorizedRequest.body)
      .pipe(catchError(err => throwError(err)));
  }
  getConfig$(): Observable<any> {
    return this._configurationSubject.asObservable();
  }
  getCasinoConfig$(): Observable<any> {
    return this._casinoConfigurationSubject.asObservable();
  }
  getGuestCasinoConfig$(): Observable<any> {
    return this._GuestCasinoConfigurationSubject.asObservable();
  } 
  setGuestCasinoConfig(response) {
    this._GuestCasinoConfigurationSubject.next(response);
  }
  getConfig() {
    const configRequest = mapConfigRequest();
    return this.httpClient.get(this.getBaseUrl(configRequest), configRequest.body)
      .pipe(map((data: any) => {
        const configData = this.serializeResponse(data.result);
        websiteSettings.data = configData;
        return configData;
      }), catchError(err => { return throwError(err) }))
      .subscribe((response) => this._configurationSubject.next(response), err => console.log('getConfig', err));
  }
  getConfiguration() {
    const configRequest = mapConfigRequest();
    return this.httpClient.get(this.getBaseUrl(configRequest), configRequest.body)
      .pipe(map((data: any) => {
        const configData = this.serializeResponse(data.result);
        websiteSettings.data = configData;
        return configData;
      }), catchError(err => { return throwError(err) }))
  }
  getCasinoConfig() {
    const configRequest = mapCasinoConfigRequest();
    return this.httpClient.post(this.getBaseUrl(configRequest), configRequest.body)
      .pipe(map((data: any) => {
        casinoGameMenuSettings.data = data.result;
        return data.result;
      }), catchError(err => { return throwError(err) }))
      .subscribe((response) => this._casinoConfigurationSubject.next(response), err => console.log('getCasinoConfig', err));
  }
  getCaptchaCode() {
    const CaptchaRequest = CaptchaCodeRequest();
    return this.httpClient.get(this.getBaseUrl(CaptchaRequest), CaptchaRequest.body).pipe(catchError(err => throwError(err)));
  }

  
  GetGuestMLConfig() {
    const getGuestMLConfigEndPointRequest = mapGetGuestMLConfigEndPointRequest();
    return this.httpClient.get(this.getBaseUrl(getGuestMLConfigEndPointRequest), getGuestMLConfigEndPointRequest.body)
    .pipe(map((data: any) => {
      GuestMLConfig.data = data.result;
      return data.result;
    }), catchError(err => { return throwError(err) }))
    // return this.httpClient.get(this.getBaseUrl(getGuestMLConfigEndPointRequest), getGuestMLConfigEndPointRequest.body)
    // .pipe(map((response: any) => { return response }), catchError(err => { return throwError(err) }));
  }
  GetGuestCasinoConfig() {
    const getGuestCasinoConfigEndPointRequest = mapGetGuestCasinoConfigEndPointRequest();
    return this.httpClient.get(this.getBaseUrl(getGuestCasinoConfigEndPointRequest), getGuestCasinoConfigEndPointRequest.body)
    .pipe(map((data: any) => {
      const sdata = this.serializeGuestCasinoConfigResponse(data.result);
      GuestCasinoConfig.data = sdata;
      this.setGuestCasinoConfig(sdata);
      return sdata;
    }),catchError(err => throwError(err)));
  }
  getFavoriteGames() {
    const favoritegamesRequest = mapFavoriteGamesRequest();
    return this.httpClient.post(this.getBaseUrl(favoritegamesRequest), favoritegamesRequest.body)
    .pipe(map((data: any) => {
      return data
    }),catchError(err => throwError(err)));
  }
  getAddfavoriteGame(addgame :any) {
    const addfavoritegameRequest = mapAddFavoriteGameRequest(addgame);
    return this.httpClient.post(this.getBaseUrl(addfavoritegameRequest), addfavoritegameRequest.body, { params: addfavoritegameRequest.queryParameter })
      .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
  }
  getRemoveFavoriteGame(removegame :any) {
    const removefavoritegameRequest = mapRemoveFavoriteGameRequest(removegame);
    return this.httpClient.post(this.getBaseUrl(removefavoritegameRequest), removefavoritegameRequest.body, { params: removefavoritegameRequest.queryParameter })
      .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
  }
  getResetPasswordStatus$(): Observable<boolean> {
    return this._ResetPasswordSubject.asObservable();
  }
  setResetPasswordStatus(): Subject<boolean> {
    return this._ResetPasswordSubject;
  }
  getBaseUrl(item: any): string {
    let baseUrl: string;
    if (item.baseUrl) {
      baseUrl = `${item.baseUrl}${item.endPoint}`;
    } else {
      baseUrl = `${apiEndPointData.data.cau}${item.endPoint}`;
    }
    return baseUrl;
  }
  serializeResponse(response: any) {
    const object = {};
    // if (response && response.configData && response.configData.length > 0) {
    //   response.configData.forEach(data => {
    //     const obj = data.split('==');
    //     object[obj[0]] = obj[1];

    //   });
    // }
    // for (let key in response) {
    //   if (response.hasOwnProperty(key)
    //       && key !== 'appAppConfig'
    //       && key !== 'appWebConfig'
    //       && key !== 'marketType'
    //       && key !== 'casinoMenu'
    //       && key !== 'casinoGameCategory'
    //       && key !== 'permissions') {
    //     object[key] = response[key];
    //   }
    // }
    for (let key in response.general) {
      if (response.general.hasOwnProperty(key)) {
        object[key] = response.general[key];
      }
    }
    for (let key in response.appWebConfig) {
      if (response.appWebConfig.hasOwnProperty(key)) {
        object[key] = response.appWebConfig[key];
      }
    }
    for (let key in response.permissions) {
      if (response.permissions.hasOwnProperty(key)) {
        object[key] = response.permissions[key];
      }
    }
    for (let key in response.b2cUserInfo) {
      if (response.b2cUserInfo.hasOwnProperty(key)) {
        object[key] = response.b2cUserInfo[key];
      }
    }
    for (let key in response.tpGamePermission) {
      if (response.tpGamePermission.hasOwnProperty(key)) {
        object['tpGamePermission'] = response.tpGamePermission;
      }
    }
    if (response && response.casinoMenu && response.casinoMenu !== null && response.casinoMenu !== undefined) {
      object['casinoMenu'] = response.casinoMenu;
    }
    if (response && response.casinoGameCategory && response.casinoGameCategory !== null && response.casinoGameCategory !== undefined) {
      object['casinoGameCategory'] = response.casinoGameCategory;
    }
    if (response && response.sportList && response.sportList !== null && response.sportList !== undefined) {
      object['sportList'] = response.sportList;
    }
    if (response && response.marketType && response.marketType !== null) {
      try {
        object['marketType'] = JSON.parse(response.marketType);
      } catch (e) {
        console.log('marketType parse error ', e);
        object['marketType'] = [];
      }
    }
    return object;
  }
  serializeGuestCasinoConfigResponse(response: any){
    const object = {};
    if (response && response.casinoMenu && response.casinoMenu !== null && response.casinoMenu !== undefined) {
      object['casinoMenu'] = response.casinoMenu;
    }
    if (response && response.casinoGameCategory && response.casinoGameCategory !== null && response.casinoGameCategory !== undefined) {
      object['casinoGameCategory'] = response.casinoGameCategory;
    }
    if (response && response.casinoGameList && response.casinoGameList !== null && response.casinoGameList !== undefined) {
      object['casinoGameList'] = response.casinoGameList.map(item => {
        let casinoGameList: any = {};
        casinoGameList.appCasinoGameID = item.cgi;
        casinoGameList.appGameName = item.gn;
        casinoGameList.appDisplayName = item.dn;   
        casinoGameList.appIsActive = item.ia;
        casinoGameList.appIcon = item.ic;
        casinoGameList.appDisplayOrder = item.dor;
        
        return casinoGameList;
      });
    }
    if (response && response.providerList && response.providerList !== null && response.providerList !== undefined) {
      object['providerList'] = response.providerList.map(item => {
        let providerList: any = {};
        providerList.appProviderID = item.pi;
        providerList.appProviderName = item.pn;
        providerList.appDisplayName = item.dn;
        providerList.appProviderLogo = item.pl;
        providerList.appIsNew = item.isn;
        providerList.appIsActive = item.ia;
        providerList.appIcon = item.ic;
        providerList.appDisplayOrder = item.dor;
        providerList.appDarkLogo = item.dl;
            
        return providerList;
      });
    }
    return object;
  }
}
export const websiteSettings: any = {
  data: ''
};
export const casinoGameMenuSettings: any = {
  data: ''
};
export const GuestMLConfig: any = {
  data: ''
};
export const GuestCasinoConfig: any = {
  data: ''
};
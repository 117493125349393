export class Stake {
matchId: number;
betId: number;
betDetailId: number;
betTitle: string;
marketType: number;
rate: number;
point: number;
isBack: boolean;
isRunnerTypeMarket: boolean;
currentProfitLoss: number;
currentSelectedItem: string;
closeMe = false;
isJodiRates: boolean;
}

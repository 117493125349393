import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ElementRef, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BetFacadeService } from '@clientApp-core/services/bet/bet.facade.service';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { StoreService } from '@clientApp-core/services/store/store.service';
import { LiabilityComponent } from '@clientApp-shared/components/liability/liability.component';
declare var $: any;
import { NewsFacadeService } from '@clientApp-core/services/news/news-facade.service';
import { take, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { SessionService } from '@clientApp-core/services/session/session.service';
import { RulesComponent } from '@clientApp-shared/components/rules/rules.component';
import { CommonService } from '@clientApp-core/services/common/common.service';
import { AuthFacadeService, websiteSettings } from '@clientApp-core/services/authentication/authentication-facade.service';
import { apiEndPointData } from '@clientApp-core/services/config/connfig.service';
import { MarketFacadeService } from '@clientApp-core/services/market/market-facade.service';
import * as M from "materialize-css/dist/js/materialize";
import { ChangePasswordComponent } from 'app/features/change-password/change-password.component';
import { SearchComponent } from '../search/search.component';
import { appTournament, leftSidebarMapMarket, mapMarketTorunaments, mapMatch } from '@clientApp-core/services/shared/dashboard-shared.service';
import { GetSortOrder } from '@clientApp-core/utilities/app-configuration';
import { NodeCentralService } from '@clientApp-core/services/session/node-central.service';
declare var _: any;

import { sortBySport } from '@clientApp-core/services/shared/JSfunction.service';
import { DepositWithdrawComponent } from 'app/features/deposit-withdraw/deposit-withdraw.component';
import { SharedModule } from '@clientApp-shared/shared.module';
import { LeftSidebarComponent } from "../sidebar/left/left.sidebar.component";
@Component({
    selector: 'pb-header',
    standalone: true,
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    imports: [SharedModule, RulesComponent, LiabilityComponent, ChangePasswordComponent, LeftSidebarComponent, DepositWithdrawComponent]
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('liability', { static: true }) liabilityRef: LiabilityComponent;
    @ViewChild('rules', { static: true }) rulesRef: RulesComponent;
    @ViewChild('changePass', { static: true }) changePassRef: ChangePasswordComponent;
    @ViewChild('search', { static: true }) searchRef: SearchComponent;
    @ViewChild('depositwithdraw', { static: true }) depositwithdrawRef: DepositWithdrawComponent;
    account: any;
    user: any;
    news: any;
    selectedmarket: any;
    isShowBalance = true;
    isShowLiability = false;
    loading = false;
    isLibShow = false;
    logoUrl = apiEndPointData.data.logoUrl;
    searchLogoUrl = apiEndPointData.data.logoUrl;
    markets = [];
    allMarkets: any = [];
    config: any;
    excludeSport = [];
    excludeSports = [];
    isListwithTournament: boolean;
    isShowOneClickBet: boolean = websiteSettings.data.isShowOneClickBet;
    selectedStakeValue: string;
    isCheckedOneClickBet: boolean = false;
    isEditOneClick: boolean = false;
    oneClickStakeData = [];
    searchStr: string;
    isNewsExits: boolean = false;
    isCasinoGameOpen: boolean = false;
    isRefreashBalance: boolean = false;
    isCheckedDarkTheme: boolean = true;
    isInplayTabOpen: boolean = true;
    selectedSport = 'Home';
    isMultiPin = false;
    isB2C: boolean;
    @ViewChild('searchInput') searchInput: ElementRef;
    constructor( public betService: BetFacadeService,
        public router: Router, private sessionService: SessionService,
        private marketService: MarketFacadeService,private nodeCentralService: NodeCentralService,
        public storeService: StoreService, private newsService: NewsFacadeService, public commonService: CommonService,
        private authService: AuthFacadeService) {
        this.router.events.subscribe(val => {
            if (val instanceof NavigationEnd) {
                const currentUrl = val.url;
                if (currentUrl.indexOf('/casinoGame') > -1) {
                    this.isShowBalance = false;
                    this.isShowLiability = true;
                } else {
                    this.isShowBalance = true;
                    this.isShowLiability = false;
                }
            }
        });
        this.commonService.getDarkThemeStatus().subscribe(isChecked => {
            this.isCheckedDarkTheme = isChecked;
            this.checkIsDarkThemeExists();
        });
    }

    ngOnInit() {
        this.onChangeDarkTheme(true);
        // this.isB2C = apiEndPointData.data.isB2C;
        this.isB2C = websiteSettings.data.isB2C;
        if(this.router.url == '/live-casino'){
            this.isCasinoGameOpen = true;
            this.isInplayTabOpen = false;
          }
	$(".dropdown-trigger").dropdown();
        // this.authService.getConfig();
        this.getConfig();
        this.isListwithTournament = websiteSettings.data.isLeftSideBarTournamentView;
        // this.getMarkets();
        this.user = JSON.parse(localStorage.getItem('token'));
        this.betService.getBalance();
        this.betService.checkBalance$().pipe(
            switchMap((resp) => {
                return of(resp);
            }
            )
        ).subscribe(value => {
            this.isRefreashBalance = false;
            this.account = value;
            if (this.account && this.account.liability) {
                this.account.liability = Math.abs(this.account.liability);
            }
        });
        this.getNews();
        const darkThemeCurrentState = this.commonService.getCookieValue('isCheckedDarkTheme');
        this.isCheckedDarkTheme = (darkThemeCurrentState != null && darkThemeCurrentState != undefined && darkThemeCurrentState != '' && JSON.parse(darkThemeCurrentState).isCheckedDarkTheme) ? true : false;
        this.commonService.setDarkThemeStatus(this.isCheckedDarkTheme);
        this.checkIsDarkThemeExists();
    }
    ngAfterViewInit() {
        let elems = document.querySelectorAll('.dropdown-trigger');
        let instances = M.Dropdown.init(elems, {});
        var tooltippedElems = document.querySelectorAll('.tooltipped');
        var instances1 = M.Tooltip.init(tooltippedElems, {});
        
        $('body').click(function() {
            document.querySelector(".sidenav").classList.remove('sidenav-close');
            document.querySelector(".sidenav").classList.remove('sidenav-open');
            document.querySelector(".custom-sidenav").classList.remove('sidenav-overlay');
        });
        $('.sidenav').click(function (e) {
            e.stopPropagation()
        });
        var scrollTop = 0;
        $(window).scroll(function(){
          scrollTop = $(window).scrollTop();
          
          if (scrollTop >= 10) {
            $('#global-nav').addClass('nav_height');
            $('#mobile-nav').addClass('sidenav-margin');
          }
          else if (scrollTop < 10) {
            $('#global-nav').removeClass('nav_height');
            $('#mobile-nav').removeClass('sidenav-margin');
           }
        });
    }
    errorHandler(event) {
        event.target.src = "assets/images/in-play-new.png";
      }
    toogleSidenavBar(event: any) {
        event.stopPropagation();
        if (document.querySelector(".sidenav").classList.contains("sidenav-open")) {
            document.querySelector(".sidenav").classList.remove('sidenav-open');
            document.querySelector(".sidenav").classList.add('sidenav-close');
            document.querySelector(".custom-sidenav").classList.remove('sidenav-overlay');
        } else {
            document.querySelector(".sidenav").classList.remove('sidenav-close');
            document.querySelector(".sidenav").classList.add('sidenav-open');
            document.querySelector(".custom-sidenav").classList.add('sidenav-overlay');
          }
    }
    getNews() {
        this.newsService.getNews$()
            .pipe(
                untilDestroyed(this),
                take(1),
                catchError(err => throwError(err))
            ).subscribe(response => {
                this.news = response;
                if ( this.news &&  this.news.length > 0) {
                    this.isNewsExits = true;
                    this.commonService.isNewsExits = true;
                }
            }, err => console.log('getNews', err));
    }

    getConfig() {
        this.authService.getConfig$()
            .pipe(
                untilDestroyed(this),
                take(1),
                catchError(err => throwError(err))
            ).subscribe(response => {
                if (response) {
                    //  console.log(response);
                    this.commonService.configData = response;
                    this.config = response;
                    this.isListwithTournament = websiteSettings.data.isLeftSideBarTournamentView;
                    // this.isShowOneClickBet = websiteSettings.data.isShowOneClickBet;
                    this.isB2C = websiteSettings.data.isB2C;
                    // this.getMarketList();
                    var tooltippedElems = document.querySelectorAll('.tooltipped');
                    var instances1 = M.Tooltip.init(tooltippedElems, {});
                }
            }, err => console.log('getConfig', err));
    }
    onLogoClick() {
        localStorage.removeItem('matchesBeforeLogin');
        localStorage.removeItem('leftMatchesBeforeLogin');
        this.commonService.setCasinoOpenStatus(true);
        this.commonService.selectedProviderStatus(true);
        this.commonService.allgamesselected(true);
        // this.storeService.removeAllStoreData();
        this.isCasinoGameOpen = true;
        // this.isInplayTabOpen = true;
        this.router.navigateByUrl('/live-casino');
    }
    onLiabilityClick() {
        if (this.account && this.account.liability !== 0) {
            this.liabilityRef.openPopup();
        }
    }
    onLogOut() {
        this.authService.LogOut$();
        this.storeService.clearStore();
        localStorage.removeItem('matchesBeforeLogin');
        localStorage.removeItem('leftMatchesBeforeLogin');
        // this.commonService.FavoriteIds = [];
    }
    redirectTo(path) {
        if (websiteSettings.data.isReportPageOpenInNewTab) {
            let newRelativeUrl = this.router.createUrlTree([path]);
            let baseUrl = window.location.href.replace(this.router.url, '');  
            window.open(baseUrl + newRelativeUrl, '_blank');
        } else {
            this.isCasinoGameOpen = true;
            this.commonService.selectedProviderStatus(true);
            this.commonService.allgamesselected(true);
            this.router.navigateByUrl(path);
        }  
    }
    onTcClick() {
        this.rulesRef.openPopup();
    }
    onChangePassClick() {
        this.changePassRef.openPopup();
    }
    onDepositWithdrawClick() {
        this.depositwithdrawRef.openPopup();
    }
    getCasinoActiveClass() {
        const currRoute = window.location.href;
        if (currRoute && (currRoute.includes('/live-casino') || currRoute.includes('/casinoGame'))) {
            return 'active';
        } else {
            return '';
        }
    }
    CasinopageOpen(){
        this.isCasinoGameOpen = true;
        this.isInplayTabOpen = false;
    }
    // show search box
    openSearchModal() {
        this.searchRef.openPopup();
    }
    searchValueChange(val) {
        this.searchStr = '';
        this.searchInput.nativeElement.blur()
    }
    searchFocusOutFn(event: any) {
        this.searchStr = ''; 
    }
    oneClickValueChange(val) {
        this.isEditOneClick = false;
    }
    onRefreshClick() {
        this.isRefreashBalance = true;
        this.betService.getBalance();
    }
    onChangeDarkTheme(isChecked: boolean) {
        document.body.classList.toggle('dark');
        if (isChecked) {
            this.isCheckedDarkTheme = true;
            this.commonService.setCookieValue('isCheckedDarkTheme', JSON.stringify({ isCheckedDarkTheme: true }));
            this.commonService.setDarkThemeStatus(true);
        } else {
            this.isCheckedDarkTheme = false;
            this.commonService.setCookieValue('isCheckedDarkTheme', JSON.stringify({ isCheckedDarkTheme: false }));
            this.commonService.setDarkThemeStatus(false);
        }
    }
    checkIsDarkThemeExists() {
        if (this.isCheckedDarkTheme) {
          document.body.classList.add('dark');
            this.logoUrl = apiEndPointData.data.lightLogoUrl;
            this.searchLogoUrl = apiEndPointData.data.lightLogoUrl;
        } else {
            this.logoUrl = apiEndPointData.data.darkLogoUrl;
            this.searchLogoUrl = apiEndPointData.data.darkLogoUrl;
        }
      }
    ngOnDestroy() { }
}

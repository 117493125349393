import { Injectable, Compiler } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { SessionService } from './../session/session.service';
import { take, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { NodeCentralService } from '../session/node-central.service';
import { ActiveMarket } from '@clientApp-core/models/market/activemarket.model';

@Injectable()
export class StoreService  {
    constructor(private router: Router,
       private sessionService: SessionService, private _compiler: Compiler, private nodeCentralService: NodeCentralService ) {}

    removeAllStoreData() {
        this.removeAllGroup();
        // this.store.dispatch(RemoveAllSelectedMarket());
    }
    removeAllGroup() {
      // const centralizationIds = this.getSelectedMarket().map(match => match.mc).toString();
      // if (centralizationIds && centralizationIds !== '' && centralizationIds.length > 0 ) {
      //   this.sessionService.removeAllCentralGroup(centralizationIds);
      // }
    }

    clearStore() {
        // this.authService.LogOut$();
        this.clearLocalStorage();
       // this.stopHubConnections();
        this.nodeCentralService.StopSocketConnection();
        // this.store.dispatch(new ClearParkBetStore());
        this.router.navigateByUrl('/login');
        this._compiler.clearCache();
        this.closeAllModal();
      }

      stopHubConnections() {
        this.sessionService.stopHubConnections();
      }

      clearLocalStorage() {
        localStorage.removeItem('token');
        localStorage.removeItem('match'); 
        localStorage.removeItem('casino'); 
        localStorage.removeItem('selected_matches');
        localStorage.removeItem('is_refreshed');
        localStorage.removeItem('showTandC');
        localStorage.removeItem('showVeronicaPopup');
        localStorage.removeItem('is_MultiPin');
        localStorage.removeItem('multiselected_matchIds');
        localStorage.removeItem('selectedSport');
        localStorage.removeItem('selected_betId');
        localStorage.removeItem('lobbyUrl');
        // localStorage.removeItem('chip');
        // localStorage.removeItem('matchesBeforeLogin');
        // localStorage.removeItem('leftMatchesBeforeLogin');
      }
      private  getSelectedMarket() {
        let selectedMarkets: ActiveMarket[] ;
        // this.store
        // .pipe(select(fromSelectedMarket.getAllMarkets), take(1), catchError(err => throwError(err)))
        // .subscribe(markets => selectedMarkets = markets, err => console.log('getSelectedMarket', err));
        // return selectedMarkets;
      }
      closeAllModal () {
      }
}

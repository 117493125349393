<!-- Search -->
<div class="search-modal">
    <div id="search" class="modal" #search>
        <div class="modal-content">
            <a class="modal-close  right" (click)="hideSearchModal()"><i class="fa fa-times" aria-hidden="true"></i></a>
            <div class="modal-body">
                <form class="col s12">
                    <div class="row p-0 m-0">
                        <div class="input-field col s12">
                            <input id="searchstr" placeholder="Search.." type="text" class="validate z-depth-0"
                                name="search" [(ngModel)]="searchStr" (keyup)="searchMatch(searchStr)" autofocus
                                autocomplete="off">
                            <button type="submit" (click)="searchMatch(searchStr)"><i class="fa fa-search"></i></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
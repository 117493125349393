import { BaseRequest } from '@clientApp-core/models/base/base-request.model';
import { PlaceBet } from '@clientApp-core/models/bet/place-bet.model';

export class BetRequest implements BaseRequest<any> {
    constructor(public body: any, public queryParameter: any, public endPoint: string, public baseUrl: string = null) { }
}



export function mapBalanceBetRequest(): BaseRequest<any> {
    const request = new BetRequest({}, {}, mapGetBalanceEndPoint());
    return request;
}

export function mapLiabilityBetRequest(): BaseRequest<any> {
    const request = new BetRequest({}, {}, mapGetLiabilityEndPoint());
    return request;
}

export function mapGetBalanceEndPoint(): string {
    const endPointUrl = 'api/Chip/Client/Balance';
    return endPointUrl;
}
export function mapGetLiabilityEndPoint(): string {
    const endPointUrl = 'api/Market/Active/GetLiabilityWiseRecord';
    return endPointUrl;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgOptimizedImage } from '@angular/common'

@NgModule({
  imports: [
    /** Common Angular Module */
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot({
      closeButton: true,
      progressBar: true,
      progressAnimation: 'decreasing',
      maxOpened: 4,
      timeOut: 3000,
      preventDuplicates: true,
      positionClass: 'toast-top-center'
    }),
    SlickCarouselModule,
    NgOptimizedImage
  ],
  declarations: [],
  exports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    SlickCarouselModule,
    NgOptimizedImage
  ]
})

export class SharedModule { }

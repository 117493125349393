import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ConfigService {
  configurations: any;
  baseurl: any;
  jsonData: any;
  constructor(private http: HttpClient) {
  }
  loadAppConfig() {
    let promise = new Promise<void>((resolve, reject) => {
      this.http.get('assets/json/config.json').subscribe((data: any) => {
        this.jsonData = data;
        this.http.get(data.getInitDataUrl + '/api/Account/GetGuestInitData')
          .toPromise()
          .then((response: any) => { // Success
            apiEndPointData.data = this.serializeResponse(response.result);  
            resolve();
          }).catch(err => {
            data.inMaintenance = true;
            apiEndPointData.data = data;
            resolve();
            console.log('Configuration Failed');
          });
      });
    });
    return promise;
  }
  apiBaseUrl() {
    if (!this.configurations) {
      throw Error('Config file not loaded!');
    }
    return this.configurations;
  }
  addOrupdateObject(data: any) {
    const initData = Object.assign([],data[0]);
    if(initData && initData.themes && initData.themes.length > 0) {
    const themeData = Object.assign([],initData.themes[0]); 
    if (themeData.idl) {
      this.jsonData['logoUrl'] = themeData.dl;
      this.jsonData['lightLogoUrl'] = themeData.ll;
    } else {
      this.jsonData['logoUrl'] = themeData.dl;
      this.jsonData['lightLogoUrl'] = themeData.ll;
    }
      this.jsonData['themeBasePath'] = themeData.tp;
    } else {
      console.log('Not found themes data');
      this.jsonData['themeBasePath'] = this.jsonData['commonContentPath'];
    }
    const mergeData = {...initData, ...this.jsonData }
    return mergeData;
  }
  serializeResponse(response: any) {
    let initData = {};
    if (response) {
      initData =Object.assign([],response.initData);
      if (response.themes && response.themes.length > 0) {
        const themeData = Object.assign([],response.themes[0]);
        initData['themes'] = themeData;
        this.jsonData['themeBasePath'] = themeData.tp;
        if (themeData.idl) {
          this.jsonData['logoUrl'] = themeData.dl;
          this.jsonData['lightLogoUrl'] = themeData.ll;
          this.jsonData['darkLogoUrl'] = themeData.dl;
        } else {
          this.jsonData['logoUrl'] = themeData.dl;
          this.jsonData['lightLogoUrl'] = themeData.ll;
          this.jsonData['darkLogoUrl'] = themeData.dl;
        }
      } else {
        console.log('Not found themes data');
        this.jsonData['themeBasePath'] = this.jsonData['commonContentPath'];
      }
      if (response.banners && response.banners !== null && response.banners !== undefined) {
        initData['banners'] = response.banners;
      }
      // if (response.casinoMenu && response.casinoMenu !== null && response.casinoMenu !== undefined) {
      //   initData['casinoMenu'] = response.casinoMenu;
      // }
      // if (response.casinoGameCategory && response.casinoGameCategory !== null && response.casinoGameCategory !== undefined) {
      //   initData['casinoGameCategory'] = response.casinoGameCategory;
      // }
      // if (response.casinoGameList && response.casinoGameList !== null && response.casinoGameList !== undefined) {
      //   initData['casinoGameList'] = response.casinoGameList;
      // }
      // if (response.providerList && response.providerList !== null && response.providerList !== undefined) {
      //   initData['providerList'] = response.providerList;
      // }
      // if (response.allActiveMarketList && response.allActiveMarketList !== null && response.allActiveMarketList !== undefined) {
      //   initData['allActiveMarketList'] = response.allActiveMarketList;
      // }
      // if (response.sportList && response.sportList !== null && response.sportList !== undefined) {
      //   initData['sportList'] = response.sportList;
      // }
      // if (response && response.marketType && response.marketType !== null) {
      //   try {
      //     initData['marketType'] = JSON.parse(response.marketType);
      //   } catch (e) {
      //     console.log('marketType parse error ', e);
      //     initData['marketType'] = [];
      //   }
      // }
      for (let key in response.appWebConfig) {
        if (response.appWebConfig.hasOwnProperty(key)) {
          initData[key] = response.appWebConfig[key];
        }
      }
    } else {
      console.log('Not found config data');
      this.jsonData['themeBasePath'] = this.jsonData['commonContentPath'];
    }
    initData['inMaintenance'] = response.initData.inMaintenance;
    const mergeData = { ...initData, ...this.jsonData };
    return mergeData;
  }

}
export const apiEndPointData: any = {
  data: ''
};


import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor } from '@clientApp-core/interceptors/authHttpInterceptor';


import { AccountStatementFacadeService } from '@clientApp-core/services/account-statement/account-statement-facade.service';
import { SessionService } from '@clientApp-core/services/session/session.service';
import { DashboardSharedService } from '@clientApp-core/services/shared/dashboard-shared.service';
import { BetFacadeService } from '@clientApp-core/services/bet/bet.facade.service';
import { CommonService } from '@clientApp-core/services/common/common.service';
import { LoggedInUserGuard } from '@clientApp-core/gaurds/logged-in-user.guard';
import { StoreService } from '@clientApp-core/services/store/store.service';
import { AuthFacadeService } from '@clientApp-core/services/authentication/authentication-facade.service';
import { NewsFacadeService } from '@clientApp-core/services/news/news-facade.service';
import { MarketFacadeService } from '@clientApp-core/services/market/market-facade.service';
import { MarketRateFacadeService } from '@clientApp-core/services/market/market-rates/market-rate-facade.service';
import { ChangePaswordFacadeService } from '@clientApp-core/services/change-password/change-password-facade.service';
import { DeviceInfoService } from '@clientApp-core/services/device-info/deviceinfo.services';
import { ResultsFacadeService } from '@clientApp-core/services/results/results.facade.service';
import { CasinoService } from '@clientApp-core/services/casino/casino.service';
import { ConfigService } from '@clientApp-core/services/config/connfig.service';
import { CommonModule } from '@angular/common';
import { JSfunctionService } from './services/shared/JSfunction.service';
import { Isb2cuserGuard } from './gaurds/isb2cuser.guard';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [],
  providers: [
    AuthFacadeService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
    SessionService,
    DashboardSharedService,
    NewsFacadeService,
    MarketFacadeService,
    AccountStatementFacadeService,
    MarketRateFacadeService,
    BetFacadeService,
    ChangePaswordFacadeService,
    CommonService,
    StoreService,
    DeviceInfoService,
    ResultsFacadeService,
    CasinoService,
    ConfigService,
    JSfunctionService,

    /** Resolvers */

    /** Gaurds */
    LoggedInUserGuard,
    Isb2cuserGuard
  ]
})
export class CoreModule { }

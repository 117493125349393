import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GetSortOrder } from '@clientApp-core/utilities/app-configuration';
import { Store } from '@ngrx/store';
import { websiteSettings } from '../authentication/authentication-facade.service';
import { CasinoService } from '../casino/casino.service';
import { CommonService } from '../common/common.service';
import { DeviceInfoService } from '../device-info/deviceinfo.services';
import { MarketRateFacadeService } from '../market/market-rates/market-rate-facade.service';
import { MarketDetail } from '../market/types/market-detail';
import { NodeCentralService } from '../session/node-central.service';
import { SessionService } from '../session/session.service';
import { StoreService } from '../store/store.service';
import { Stake } from '@clientApp-core/models/bet/stake.model';
import { BetFacadeService } from '../bet/bet.facade.service';
import { AppInjector } from 'app/app-injector';
import { mapappMatch, mapGroupByKey, mapUniqueData, sumByKey } from './JSfunction.service';

@Injectable()
export class DashboardSharedService  {
  constructor() { } 
}

export function mapLiability(markets) {
  let group = mapGroupByKey(markets, 'eid');
  return Object.keys(group).map(key => ({
        liability: group[key],
        totalLiability: sumByKey(group[key], 'appLiability'),
        appDate: group[key][0]['appDate'],
        appSport: group[key][0]['appSport'],
        appTournament: group[key][0]['appTournament'],
        appMatch: group[key][0]['appMatch'],
        appbetname: group[key][0]['appbetname']
  }));
}
export function mapMarket(markets) {
  const uniqueMarkets = mapUniqueData(markets, 'eid');
  let group = mapGroupByKey(uniqueMarkets, 'st');
  return Object.keys(group).map(key => ({
                sport: key,
                matches: group[key]
        }));
}
export function leftSidebarMapMarket(markets) {
  let group = mapGroupByKey(markets, 'st');
  return Object.keys(group).map(key => ({
                sport: key,
                matches: group[key]
        }));
}
export function mapMatch(markets) {
  let group = mapGroupByKey(markets, 'en');
  return Object.keys(group).map(key => ({
                name: key,
                values: group[key]
        }));
}
export function mapMarketTorunaments(markets) {
  let group = mapGroupByKey(markets, 'st');
  return Object.keys(group).map(key => ({
                sport: key,
                appTournament: group[key]
        }));
}
export function appTournament(markets) {
  let tournamentGroup = mapGroupByKey(markets, 'tn');
  return  Object.keys(tournamentGroup).map(key => ({
                tournamentName: key,
                matches: mapappMatch(tournamentGroup[key])
  }));
}


export function removeAllGroup(matches: any[],uniqueMarkets) {
  const deviceInfoService = AppInjector.get(DeviceInfoService);
  if (!deviceInfoService.isMobile() && !websiteSettings.data.isSocketRateEnabled && uniqueMarkets && uniqueMarkets.length > 0) {
    const centralizationIds = uniqueMarkets.map(match => match.mc).filter(function (el) {
      return el != null && el != undefined && el != '';
    }).toString();
    const sessionService = AppInjector.get(SessionService);
    sessionService.removeCentralDashboardGroup(centralizationIds);
  }
}

export function onCasinoGameClickEvent(param) {
 
  const commonService = AppInjector.get(CommonService);
  commonService.setLoadingStatus(true);
  const casinoService = AppInjector.get(CasinoService);
  casinoService.getCasinoToken(param);
  // localStorage.removeItem('casino'); 
  }


export function leftmatchesBeforeLogin(match, betId?){
 
  const selectedMatch = [];
  // selectedMatch.push(match.values[0].eid);
  if(betId){
    selectedMatch.push(match.eid);
    localStorage.setItem('match', JSON.stringify(match));
  }else{
    selectedMatch.push(match.values[0].eid);
    localStorage.setItem('match', JSON.stringify(match.values[0]));
  }
  localStorage.removeItem("selected_matches");
  localStorage.setItem("selected_matches", JSON.stringify(selectedMatch));
  const storeService = AppInjector.get(StoreService);
  // storeService.removeAllStoreData();
  const stake = new Stake();
  stake.closeMe = true;
  const betService = AppInjector.get(BetFacadeService);
  betService.setStake().next(stake);
  let currentMenuIndex = 0;
  let currentTournamentIndex = 0;
  var centralizationIds: any;
  if(betId){
  centralizationIds = match.mc.toString();
  } else {
  centralizationIds = match.values
  .map((x) => x.mc)
  .toString();
  }
  const sessionService = AppInjector.get(SessionService);
  sessionService.joinMultiCentralGroup(centralizationIds);
  if (betId) {
    sessionService.addMarketGroup(
      "",
      3,
      match.eid,
      match.mid,
      match.mc,
      0,
      0,
      0
    );
      const store = AppInjector.get(Store);
      // store.dispatch(SelectedMarket.UpsertSelectedMarket({ market: match }));
  } else{
    match.values.forEach(market => {
      sessionService.addMarketGroup(
        "",
        3,
        market.eid,
        market.mid,
        market.mc,
        0,
        0,
        0
      );
      const store = AppInjector.get(Store);
      // store.dispatch(SelectedMarket.UpsertSelectedMarket({ market: market }));
    });
  }
  const commonService = AppInjector.get(CommonService);
  commonService.setShowLoginDashboardStatus(true);
}

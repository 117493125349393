<div id="deposit" class="modal modal-fixed-footer" #depositwithdrw>
    
    <div class="modal-content">
      <a href="javascript:void(0)" (click)="close()" class="modal-close right"><i class="fa fa-times" aria-hidden="true"></i></a>
      <h5>Deposit / Withdraw </h5>
      <div class="modal-body">
        <form >
          <div class="row deposit-box">
            <label class="mr-1">
              <input name="group1" type="radio" (click)="model.option = 'deposit'" [checked]="model.option === 'deposit'" (change)="onDWItemChange($event.target.value)" />
              <span>Deposit</span>
            </label>

            <label>
              <input name="group1" type="radio" (click)="model.option = 'withdraw'" [checked]="model.option === 'withdraw'" (change)="onDWItemChange($event.target.value)" />
              <span>Withdraw</span>
            </label>
          </div>
        </form>
        <div class="deposit-modal" *ngIf="model.option === 'deposit'">
          <form [formGroup]="depositForm" (ngSubmit)="depositRequest()">
            <div class="row">
              <div class="input-field col s12 m12" *ngIf="depositForm.get('amount') as amountCtrl">
                <input placeholder="Enter Amount" type="text" class="validate" formControlName="amount"  maxlength="8" (keypress)="numberOnly($event)" (keyup)="AmountCheck($event)">
                <label class="active">Amount<span class="text-danger">*</span>:</label>
                <span class="deposite" data-error="wrong" data-success="right">
                  You Can Add upto <b>{{Amountminmax.dmax}}</b>, minimum <b>{{Amountminmax.dmin}}</b> required</span>
                <div *ngIf="amountCtrl.errors && (amountCtrl.dirty || amountCtrl.touched)">
                  <span class="helper-text error  left-align" data-error="wrong" data-success="right"
                    *ngIf="amountCtrl.errors.required">Please enter valid amount</span>
                </div>
                <div *ngIf="isdepositminmax">
                  <span class="helper-text error  left-align" data-error="wrong" data-success="right">
                    Deposit amount must be between {{Amountminmax.dmin}} and {{Amountminmax.dmax}}.</span>
                </div>
              </div>
            </div>
          </form>

        </div>
        <!-- deposit-modal div over -->
        <div class="withraw-modal" *ngIf="model.option === 'withdraw'">

          <div class="kyc-staus-box">
                <div class="">
                    <span class="kyc-title"> KYC Status : 
                      <span class="status pending" *ngIf="KYCStatus == '' || KYCStatus == null" >Pending</span>
                      <span class="status approved" *ngIf="KYCStatus == 'Approved'">{{KYCStatus}} </span>
                      <span class="status decline" *ngIf="KYCStatus == 'Declined'">{{KYCStatus}} </span>
                      <span class="status pending" *ngIf="KYCStatus == 'Pending'">InProgress</span>
                     </span>
                    <span class="right" *ngIf="KYCStatus !== 'Approved' ? (KYCStatus !== 'Pending' ? true : false) : false">
                      <a class="waves-effect waves-light btn update-kyc" (click)="updateKyc()" *ngIf="!isUpdateKYC">Update KYC</a>
                      <!-- <a class="waves-effect waves-light btn back-btn" (click)="updateKyc()" *ngIf="isUpdateKYC">Back</a> -->
                    </span>
                    <span class="kyc-title">
                      <span class="declinemsg"  *ngIf="KYCStatus == 'Declined'">Reason: {{DeclineKYCStatus}}</span>
                     </span>
                </div>
          </div>
          <div class="bank-details" *ngIf="KYCStatus === 'Approved'">
            <div class="bank-status">
              <form [formGroup]="withdrawForm" (ngSubmit)="withdrawRequest()">
                <div class="row" *ngIf="isBankDetail">
                  <div class="input-field col s12 m6 mt-20 m-0" *ngIf="withdrawForm.get('bankDetailId') as bankDetailCtrl">
                    <select id="b2cSelect" formControlName="bankDetailId" [(ngModel)]="bankDetailId" (ngModelChange)="ondocumentTypeChange($event)">
                      <option value="0" disabled selected>Choose your bank</option>
                      <option *ngFor="let item of bankDetails" [ngValue]="item.appBankDetailId">{{item.appBankName}}</option>
                    </select>
                    <label class="active">Bank Detail<span class="text-danger">*</span>:</label>
                    <div *ngIf="bankDetailCtrl.errors && (bankDetailCtrl.dirty || bankDetailCtrl.touched)">
                      <span class="helper-text error  left-align" data-error="wrong" data-success="right"
                        *ngIf="bankDetailCtrl.errors.required">The Bank Detail field is required</span>
                    </div>
                    <div *ngIf="this.withdrawForm.value.bankDetailId == 0">
                      <span class="helper-text error  left-align" data-error="wrong" data-success="right">Please Select your Bank</span>
                    </div>
                  </div>
                  <div class="input-field col s12 m6  m-0">
                    <span class="left">
                      <a class="waves-effect btn update-kyc" (click)="onAddBankDeatailBtnClick(isAddBankDetail)">Add Bank</a>
                    </span>
                  </div>
                </div>
                <div class="row" *ngIf="!isAddBankDetail">
                
                  <div class="input-field col s12 m6" *ngIf="withdrawForm.get('amount') as wamountCtrl">
                    <input placeholder="Enter Amount" type="text" class="validate" formControlName="amount" maxlength="8" (keypress)="numberOnly($event)" (keyup)="WithdrawAmountCheck($event)">
                    <label class="active">Withdrawal Amount<span class="text-danger">*</span>:</label>
                    <span class="withdrawmsg" data-error="wrong" data-success="right"
                    >You Can Withdraw upto <b>{{Amountminmax.wmax}}</b>, minimum <b>{{Amountminmax.wmin}}</b> required</span>
                    <div *ngIf="wamountCtrl.errors && (wamountCtrl.dirty || wamountCtrl.touched)">
                      <span class="helper-text error  left-align" data-error="wrong" data-success="right"
                        *ngIf="wamountCtrl.errors.required">The Withdrawal Amount field is required</span>
                    </div>
                    <div *ngIf="iswithdrawminmax">
                      <span class="helper-text error  left-align" data-error="wrong" data-success="right">
                        Withdraw amount must be between {{Amountminmax.wmin}} and {{Amountminmax.wmax}}.</span>
                    </div>
                  </div>
                </div>
              </form>
              <form [formGroup]="addBankDetailForm" (ngSubmit)="addBankDetailRequest()" *ngIf="isAddBankDetail">
                <div class="row">
                  <div class="input-field col s12 m6" *ngIf="addBankDetailForm.get('bankName') as bankNameCtrl">
                    <!-- <input placeholder="Bank Name" type="text" class="validate" formControlName="bankName"> -->
                    <select searchable="Search" formControlName="bankName">
                      <option>Choose your bank</option>
                      <option *ngFor="let item of bankList" [ngValue]="item.bn">{{item.bn}}</option>
                    </select>
                    <!-- <input type="text" id="autocomplete-input" class="autocomplete">
                    <label for="autocomplete-input">Autocomplete</label> -->
                    <label class="active">Bank Name<span class="text-danger">*</span>:</label>
                    <div *ngIf="bankNameCtrl.errors && (bankNameCtrl.dirty || bankNameCtrl.touched)">
                      <span class="helper-text error  left-align" data-error="wrong" data-success="right"
                        *ngIf="bankNameCtrl.errors.required">The Bank Name field is required</span>
                    </div>
                    <div *ngIf="this.addBankDetailForm.value.bankName == 0">
                      <span class="helper-text error  left-align" data-error="wrong" data-success="right">
                        The Space contain not required</span>
                    </div>
                  </div>
                  <div class="input-field col s12 m6" *ngIf="addBankDetailForm.get('accountNo') as bankAccountNoCtrl">
                    <input placeholder="Enter Bank Account No" type="text" class="validate" formControlName="accountNo" (keyup)="checkvalue($event)" >
                    <label class="active">Bank Account No.<span class="text-danger">*</span>:</label>
                    <div *ngIf="bankAccountNoCtrl.errors && (bankAccountNoCtrl.dirty || bankAccountNoCtrl.touched)">
                      <span class="helper-text error  left-align" data-error="wrong" data-success="right"
                        *ngIf="bankAccountNoCtrl.errors.required">The Bank Account No. field is required</span>
                    </div>
                    <div *ngIf="this.addBankDetailForm.value.accountNo == 0">
                      <span class="helper-text error  left-align" data-error="wrong" data-success="right">
                        The Space contain not required</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="input-field col s12 m6" *ngIf="addBankDetailForm.get('ifsc') as branchIFSCCtrl">
                    <input placeholder="Enter Branch IFSC" type="text" class="validate" formControlName="ifsc" (keyup)="checkvalue($event)">
                    <label class="active">Branch IFSC<span class="text-danger">*</span>:</label>
                    <div *ngIf="branchIFSCCtrl.errors && (branchIFSCCtrl.dirty || branchIFSCCtrl.touched)">
                      <span class="helper-text error  left-align" data-error="wrong" data-success="right"
                        *ngIf="branchIFSCCtrl.errors.required">The Branch IFSC field is required</span>
                    </div>
                    <div *ngIf="this.addBankDetailForm.value.ifsc == 0">
                      <span class="helper-text error  left-align" data-error="wrong" data-success="right">
                        The Space contain not required</span>
                    </div>
                  </div>
                  <div class="input-field col s12 m6" *ngIf="addBankDetailForm.get('accountHolderName') as accountHolderNameCtrl">
                    <input placeholder="Enter Name" type="text" class="validate" formControlName="accountHolderName">
                    <label class="active">Account Holder Name<span class="text-danger">*</span>:</label>
                    <div *ngIf="accountHolderNameCtrl.errors && (accountHolderNameCtrl.dirty || accountHolderNameCtrl.touched)">
                      <span class="helper-text error  left-align" data-error="wrong" data-success="right"
                        *ngIf="accountHolderNameCtrl.errors.required">The Account Holder Name field is required</span>
                    </div>
                    <div *ngIf="this.addBankDetailForm.value.accountHolderName == 0">
                      <span class="helper-text error  left-align" data-error="wrong" data-success="right">
                        The Space contain not required</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="input-field col s12 m6  m-0">
                    <span class="left">
                      <a class="waves-effect  btn update-kyc" (click)="addBankDetailRequest()">Add Bank </a>
                      <a class="waves-effect  btn update-kyc" style="margin-left: 5px; background-color: #4bc1ff;" (click)="onAddBankDeatailBtnClick(isAddBankDetail)">Back</a>
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="add-document" *ngIf="KYCStatus !== 'Approved' && isUpdateKYC">
            <form [formGroup]="KYCForm" (ngSubmit)="KYCRequest()">
                <div class="row m-0">
                  <div class="input-field col s12 m6 mt-20 m-0" *ngIf="KYCForm.get('documentType') as documentTypeCtrl">
                    <select id="b2cSelect" formControlName="documentType" [(ngModel)]="documentType" (ngModelChange)="ondocumentTypeChange($event)">
                      <option value="" disabled selected>Choose your option</option>
                      <option *ngFor="let item of documentTypes" [ngValue]="item.kmi">{{item.nm}}</option>
                    </select>
                    <label class="active">Identity Proof<span class="text-danger">*</span>:</label>
                    <div *ngIf="documentTypeCtrl.errors && (documentTypeCtrl.dirty || documentTypeCtrl.touched)">
                      <span class="helper-text error  left-align" data-error="wrong" data-success="right"
                        *ngIf="documentTypeCtrl.errors.required">The Identity Proof field is required</span>
                    </div>
                  </div>
                  <div class="col s12 m6 upload-document">
                    <form>
                      <label>Choose Photo: <span class="text-danger">*</span></label>
                      <div class="file-field input-field">
                        <div style="width: 100%;float: left;position: relative;z-index: 9;">
                        <div class="btn mb-10">
                          <span>Choose File </span>
                          <input type="file" accept="image/*" (change)="onSelectFile($event)">
                          <br/>
                        </div>
                      </div>
                        <!-- <div class="file-path-wrapper">
                          <input class="file-path validate" type="text">
                        </div> -->
                        
                        <span class="helper-text error  left-align" data-error="wrong" data-success="right"
                            *ngIf="isRequiredDocumentCtrl">The Photo field is required</span>
                      </div>
                    </form>
                  </div>
  
                </div>

              <div class="row m-0" >
                
                  <div class="col s12 m6 document-type" *ngIf="KYCForm.get('documentNo') as documentNoCtrl">
                      <div class="row m-0">
                        <div class="input-field col s12 p-0 document-details">
                          <input placeholder="Enter {{documentName}} Number" type="text" class="validate" formControlName="documentNo" (keyup)="validationdocument($event)">
                          <label class="active">{{documentName}} Number<span class="text-danger">*</span>: </label>
                          <div *ngIf="documentNoCtrl.errors && (documentNoCtrl.dirty || documentNoCtrl.touched)">
                            <span class="helper-text error  left-align" data-error="wrong" data-success="right"
                              *ngIf="documentNoCtrl.errors.required">The {{documentName}} Number field is required</span>
                          </div>
                          <div *ngIf="this.KYCForm.value.documentNo == 0">
                            <span class="helper-text error  left-align" data-error="wrong" data-success="right">The Space contain not required</span>
                          </div>
                            <div *ngIf="documentName">
                              <span class="helper-text error  left-align" data-error="wrong" data-success="right"
                              *ngIf="isdocumentcheck">Enter Valid {{documentName}} Number</span>
                            </div>
                        </div>
                      </div>
                  </div>
                  <div class="col s12 m6 document-type">
                    <img *ngIf="documentImg" [src]="documentImg" height="auto" width="200" alt="">
                  </div>

              </div>
            </form>
          </div>

          <div class="add-document" *ngIf="KYCStatus === 'Declined' && !isUpdateKYC">
                <div class="row m-0">
                  <div class="input-field col s12 m6 mt-20 m-0">
                    <label class="active">
                      <span class="documentlabel">Identity Proof</span>
                      <span class="documentvalue">:  {{documentname}}</span>
                    </label>
                    <div class="mt-10 mb-5">
                      <label class="active">
                        <span class="commoncolor">Reference Number</span>
                        <span class="commoncolor">: {{documentno}}</span>
                      </label>
                    </div>
                  </div>
                  <div class="col s12 m6 upload-document">
                      <label><span class="commoncolor">Photo:</span></label>
                      <div class="file-field input-field">
                        <div style="width: 100%;float: left;position: relative;z-index: 9;">
                          <img  [src]="getdocumentImg" height="auto" width="200" alt="">
                          <br/>
                      </div>
                      </div>
                  </div>
                </div>
          </div>
        </div>
          <!-- withraw-modal div over -->
          <div class="payment-footer" style="position: unset;">
            <p >Accept, process &amp; disburse digital payments for your business. <br>
              <span class="text-success">Fast &amp; Secure Payment</span>
            </p>
            <img [src]="commonService.contentRelativePath('assets/images/payment-footer-logo.png')" class="m-auto responsive-img">
          </div>

			</div>
    </div>
    <div class="modal-footer">
      <div class="row submit-btn">
        <a href="javascript:void(0)" class="modal-close  red btn" (click)="close()"><span class="animate-btn">Close</span></a>
        <a href="javascript:void(0)"  [ngClass]="loading?'login-disable':''" *ngIf="model.option === 'deposit'" class="green btn mr-10" (click)="depositRequest()"><i *ngIf="loading" class="fa fa-circle-notch fa-spin fa-sm" style="font-size: 12px;margin-right: 2px;"></i><span class="animate-btn">Deposit</span></a>
        <a href="javascript:void(0)"  [ngClass]="loading?'login-disable':'' || hidewithdrawrequest?'disabled':''" *ngIf="model.option === 'withdraw' && !isUpdateKYC && KYCStatus === 'Approved'" class="green btn mr-10" (click)="withdrawRequest()"><i *ngIf="loading" class="fa fa-circle-notch fa-spin fa-sm" style="font-size: 12px;margin-right: 2px;"></i><span class="animate-btn">Withdraw Request</span></a>
        <a href="javascript:void(0)"  [ngClass]="loading?'login-disable':''" *ngIf="model.option === 'withdraw' && isUpdateKYC && KYCStatus !== 'Approved'" class="green btn mr-10" (click)="KYCRequest()"><i *ngIf="loading" class="fa fa-circle-notch fa-spin fa-sm" style="font-size: 12px;margin-right: 2px;"></i><span class="animate-btn">Submit</span></a>
      </div>
    </div>
  </div>
  <div id="change-password" class="modal modal-fixed-footer" #depositSucess style="height: 30% !important;">
    <div class="modal-content">
        <a href="javascript:void(0)"  class="modal-close right"><i
                class="fa fa-times" aria-hidden="true"></i></a>
        <div class="change-password-content">
            <h5>Payment  inProcess</h5>
            <div class="modal-body">
              Payment is inprocess, Plese wait or reload page after sometimes.
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="row submit-btn">
            <a  href="javascript:void(0)" class="green btn" (click)="onOKClick()"><span class="animate-btn">Ok</span></a>
            
        </div>
    </div>
</div>
<div id="otp-popup" class="modal" #OtpVerify>
    
  <div class="modal-content" style="height: 100%;">
    <a href="javascript:void(0)" class="modal-close right" (click)="dpotpclose()"><i class="fa fa-times" aria-hidden="true"></i></a>
   
    <div class="otp-page" *ngIf="!isotpformopen">
      <div class="otp-details">
        
        <div class="otp-verify">
          <form>
           
            <div class="mt-10 mb-10">
              <div class="otp-image">
                <img  [src]="commonService.contentRelativePath('assets/images/enter-otp.png')" class="img-fluid"/>
              </div>
              <div *ngIf="!isEditNumber">
              Mobile No: {{mobileno}} 
                <a  href="javascript:void(0)" class="editnumber" (click)="EditNumber()"><i class="far fa-edit"></i></a>
                <!-- <label class="enter-otp">Enter OTP</label> -->
              </div>
              <div *ngIf="isEditNumber">
            <label  class="active">Mobile No</label>
              <ngx-intl-tel-input [cssClass]="'custom-input'" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true"
              [customPlaceholder]="PhonePacleHolder" [searchCountryFlag]="true"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.India" [maxLength]="15" [phoneValidation]="true"
              [separateDialCode]="separateDialCode" [numberFormat]="PhoneNumberFormat.National" name="mobile"
              [(ngModel)]="mobilenoshow"  (keyup)="mobilekeyup($event)" class="validate mobile-input">
            </ngx-intl-tel-input>
              <a href="javascript:void(0)" class="closeinput" (click)="CancelNumber()">          
                 <i class="fas fa-times-circle"></i>
              </a>
              <div>
                <span class="helper-text error  left-align" data-error="wrong" data-success="right"
                *ngIf="numberrequiredvalidation">The Whatsapp No field is required</span>
              
              </div>
              <div>
                 <span class="helper-text error  left-align" data-error="wrong" data-success="right"
                *ngIf="numbervalidation">Enter valid Whatsapp number.</span> 
              </div>
              </div>
            </div>
            <button class="btn btn-verify" (click)="sendOTPRequest()" [disabled]="loading">
              <i *ngIf="loading" class="fa fa-circle-notch fa-spin fa-sm" style="font-size: 12px;"></i>
              <span class="animate-btn">
                Verify
              </span>
            </button>
          </form>
          
        </div>

      </div>    
  </div>
    <div class="otp-page" *ngIf="isotpformopen">
        <div class="otp-details">
          
          <div class="otp-verify">
            <form [formGroup]="otpVerifyForm">
             
              <div class="mt-10 mb-10">
                <div class="otp-image">
                  <img [src]="commonService.contentRelativePath('assets/images/enter-otp.png')" class="img-fluid"/>
                </div>
                <div>
                  <label class="enter-otp">Enter OTP</label>
                </div>
                <ng-container *ngFor="let input of formInput;  index as i">                  
                  <input #formRow type="number" formControlName="{{input}}" pattern="[0-9]{1}"
                         maxlength="1"  size="1" min="0" max="9" (keydown)="keyUpEvent($event, i)">
                </ng-container>
                <div *ngIf="isOtpCheck">
                  <span  class="helper-text error  left-align" data-error="wrong" data-success="right">Only 1 Number can Add</span>
                  </div>
              </div>
              <button class="btn btn-verify" (click)="otpVerificationClick()" [disabled]="loading || !otpVerifyForm.valid">
                <i *ngIf="loading" class="fa fa-circle-notch fa-spin fa-sm" style="font-size: 12px;"></i>
                <span class="animate-btn">
                  Verify
                </span>
              </button>
              <div class="send-code">
                <div class="text"> Didn't receive the code? </div>
                <div class="" [ngClass]="otpAgainDisabled?'disablecode':''">
                  <a href="javascript:void(0)" class="resend-code" (click)="reSendOtpClick()">Send code again</a>
                </div>
                <span *ngIf="otptimershow">{{OTPtimer}}</span>
              </div>
            </form>
            
          </div>

        </div>    
    </div>
  </div>
</div>
  <!-- Rules -->
  <div id="rules" class="modal modal-fixed-footer" #rules>
    <div class="modal-content">
      <a href="javascript:void(0)" (click)="hideModal()" class="modal-close right"><i class="fa fa-times" aria-hidden="true"></i></a>
      <h5>{{name ? name : 'Rules'}}</h5>
      <div class="modal-body" [innerHTML]="marketRules"></div>
    </div>
    <div class="modal-footer">
      <a href="javascript:void(0)" (click)="hideModal()" class="modal-close red btn"><span class="animate-btn">Close</span></a>
    </div>
  </div>
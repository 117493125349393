import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ShowDepositButtionOption } from '@clientApp-core/enums/showDepositButtionOption.types';
import { AuthFacadeService, websiteSettings } from '@clientApp-core/services/authentication/authentication-facade.service';
import { B2cUserService } from '@clientApp-core/services/authentication/b2c-user.service';
import { BonusService } from '@clientApp-core/services/authentication/bonus.service';
import { CommonService } from '@clientApp-core/services/common/common.service';
import { apiEndPointData } from '@clientApp-core/services/config/connfig.service';
import { SharedModule } from '@clientApp-shared/shared.module';
import * as M from "materialize-css/dist/js/materialize";
import { CountryISO, NgxIntlTelInputModule, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-deposit-withdraw',
  standalone: true,
  imports: [SharedModule,NgxIntlTelInputModule],
  templateUrl: './deposit-withdraw.component.html',
  styleUrls: ['./deposit-withdraw.component.scss']
})
export class DepositWithdrawComponent implements OnInit,AfterViewInit {
  @ViewChild('depositwithdrw', { static: true }) template: ElementRef;
  @ViewChild('depositSucess', { static: true })  dstemplate: ElementRef;
  @ViewChild('OtpVerify', { static: true }) OtpVerify: ElementRef;
  @ViewChildren('formRow') rows: any;
  modalInstances: any;
  dsmodalInstances: any;
  otpVerifyModalInstances: any;
  otpVerifyForm: UntypedFormGroup;
  formInput = ['input1', 'input2', 'input3', 'input4'];
  model = { option: 'deposit' };
  KYCStatus: string;
  DeclineKYCStatus: string;
  isUpdateKYC: boolean = false;
  isdocumentcheck: boolean = false;
  documentTypes = [];
  bankList = [];
  documentType = 1;
  documentName = '';
  withdrawForm = this.fb.group({
    // mobileNo: ['', [Validators.required,  Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    amount: ['', Validators.required],
    bankDetailId: ['', Validators.required],
    // description: ['', Validators.required]
  });
  addBankDetailForm = this.fb.group({
    bankName: ['', Validators.required],
    accountNo: ['', Validators.required],
    ifsc: ['', Validators.required],
    accountHolderName: ['', Validators.required]
  });
  depositForm = this.fb.group({
    amount: ['', Validators.required],
  });
  manualDepositForm = this.fb.group({
    Amount: ['', Validators.required],
    UTRTransactionNo: ['', Validators.required],  // , Validators.required
    Document: ['', Validators.required],
    promoCode:['']
  });
  KYCForm = this.fb.group({
    documentType: ['', Validators.required],
    documentNo: ['', Validators.required],
    document: ['', Validators.required],
  });
  documentImg: any;
  isRequiredDocumentCtrl: boolean = false;
  loading = false;
  bankDetailId = 0;
  bankDetails: any = [];
  isAddBankDetail: boolean = false;
  isBankDetail: boolean = true;
  Amountminmax: any = {};
  isdepositminmax: boolean = false;
  iswithdrawminmax: boolean = false;
  holdername: string;
  bankname: string;
  accountno: string;
  ifsccode: string;
  documentno: string;
  documentname: string;
  getdocumentImg: any;
  hidewithdrawrequest: boolean = false;
  isOtpCheck: boolean = false;
  isB2C: boolean;
  isMobileVerify: boolean = false;
  mobileno: string;
  mobilenoshow: any;
  isEditNumber: boolean = false;
  isotpformopen: boolean = false;
  isMobileUser: string;
  timerOn = true;
  otpAgainDisabled: boolean = true;
  ResendOTPCount: number;
  ResendOTPRemainCount: number = 0;
  FPResendOTPCount: number = 0;
  OTPtimer: string;
  otptimershow: boolean = false;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  PhonePacleHolder: string = '789XXXXXXX';
	SearchCountryField = SearchCountryField;
  separateDialCode = false;
  PhoneNumberFormat = PhoneNumberFormat;
  numberrequiredvalidation: boolean = false;
  numbervalidation: boolean = false;
	CountryISO = CountryISO;
  mobilenumber: number;
  isManualDeposit: boolean;
  isRechargeDeposit: boolean;
  constructor(private toastr: ToastrService, public commonService: CommonService,  private b2cUserService: B2cUserService, private fb: UntypedFormBuilder,
   public authFacadeService: AuthFacadeService, private bonusService: BonusService) {
    this.otpVerifyForm = this.toFormGroup(this.formInput);
   }

  ngOnInit(): void {
    this.isB2C = websiteSettings.data.isB2C;
    this.isManualDeposit = websiteSettings.data.sdbo == ShowDepositButtionOption.Both || websiteSettings.data.sdbo == ShowDepositButtionOption.Manual;
    this.isRechargeDeposit = websiteSettings.data.sdbo == ShowDepositButtionOption.Both || websiteSettings.data.sdbo == ShowDepositButtionOption.Recharge;
    this.isMobileVerify = websiteSettings.data.imv;
    this.mobileno = websiteSettings.data.mno;
    this.mobilenoshow = this.mobileno;
    this.ResendOTPCount = apiEndPointData.data.ResendOTPLimit;
  }

  ngAfterViewInit() {
    this.modalInstances = M.Modal.init(this.template.nativeElement, { dismissible: false });
    this.dsmodalInstances = M.Modal.init(this.dstemplate.nativeElement, {dismissible: false});
    this.otpVerifyModalInstances = M.Modal.init(this.OtpVerify.nativeElement, {dismissible: false, opacity: 2.7});
      this.PhoneinitSelection();
  }
  PhoneinitSelection(){

window.addEventListener('click', (event) => {
 const elemn =  document.querySelectorAll("ngx-intl-tel-input");
   elemn.forEach(element => {
     element.getElementsByTagName('input')[0].style.paddingBottom = '0px';
     element.getElementsByTagName('input')[0].style.paddingTop = '20px';
     element.getElementsByTagName('input')[0].style.height = '2rem';
  //  var das  = element.getElementsByClassName("iti__selected-flag dropdown-toggle"); 
});
});

  }
  changePreferredCountries() {
		this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  mobilekeyup(event: any){
    if(event.target.value.length == 10){
        this.numbervalidation = false;
          } else {
        this.numbervalidation = true;
      }
      if(event.target.value != "" && event.target.value != null){
        this.numberrequiredvalidation = false; 
      } else if(event.target.value == "" || event.target.value == null){
        this.numberrequiredvalidation = true; 
      }
    }
  checkvalue(event: any) {
    event.target.value  = event.target.value.replace(/\s/g, '');
  }
  toFormGroup(elements) {
    const group: any = {};
    elements.forEach(key => {
      group[key] = new UntypedFormControl('', Validators.required)
    });
    return new UntypedFormGroup(group);
  }
  keyUpEvent(event, index) {
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1 ;
      if (pos > -1 && pos < this.formInput.length && event.target.value.length == 0) {
        this.rows._results[pos].nativeElement.focus();
      } else if(event.target.value.length == 1 && event.keyCode === 8 && event.which === 8){
        return event.target.value.slice(0, 1);
      }
    } else {
      if(event.target.value.length > 1){
        this.isOtpCheck = true;
          return event.target.value.slice(0, 1);
        } else if(event.target.value.length == 1)  {
          this.isOtpCheck = false;
          this.rows._results[pos + 1].nativeElement.focus();
     }
    }
  }
  AmountCheck(event: any){
      if(event.target.value >= this.Amountminmax.dmin && event.target.value <= this.Amountminmax.dmax){
        this.isdepositminmax = false;
       } else {
        this.isdepositminmax = true;
      }
  }
  WithdrawAmountCheck(event: any){
    if(event.target.value >= this.Amountminmax.wmin && event.target.value <= this.Amountminmax.wmax){
      this.iswithdrawminmax = false;
      this.hidewithdrawrequest = false;
     } else {
      this.hidewithdrawrequest = true;
      this.iswithdrawminmax = true;
    }
  }
  onSelectFile(event): void {
    this.readThis(event.target);
  }
  readThis(inputValue: any): void {
    if (inputValue.files && inputValue.files[0]) {
      
      var file: File = inputValue.files[0];
      var myReader: FileReader = new FileReader();

      myReader.onloadend = (e) => {
        this.documentImg = myReader.result;
        this.KYCForm.controls['document'].setValue(myReader.result);
      }
      myReader.readAsDataURL(file);
      this.isRequiredDocumentCtrl = false;
    } else {
      this.documentImg = '';
      this.isRequiredDocumentCtrl = true;
      this.KYCForm.controls['document'].setValue('');
    }
    
  }
  initSelect() {
    var elems = document.getElementById('b2cSelect');
    var instances = M.FormSelect.init(elems, {});
    }
    initSelection(){
      document.querySelectorAll('select[searchable]').forEach(elem => {
        const select = M.FormSelect.init(elem, {});
        select.input.placeholder = 'Select Bank';
        const options = select.dropdownOptions.querySelectorAll('li:not(.optgroup)');
        options.forEach(option => {
          option.style.minHeight = '25px';
         option.getElementsByTagName('span')[0].style.color = '#000000';
         option.getElementsByTagName('span')[0].style.padding = '5px 16px'; 
         option.getElementsByTagName('span')[0].style.fontSize = '12px'; 
         option.getElementsByTagName('span')[0].style.fontWeight = '500'; 
         option.getElementsByTagName('span')[0].style.lineHeight = '16px'; 

      });
        // Add search box to dropdown
        const placeholderText = select.el.getAttribute('searchable');
        const searchBox = document.createElement('div');
        searchBox.style.padding = '6px 16px 0 16px';
        searchBox.innerHTML = `
            <input type="text" placeholder="${placeholderText}">
            </input>`
        select.dropdownOptions.prepend(searchBox);
        // Function to filter dropdown options
        function filterOptions(event) {
            const searchText = event.target.value.toLowerCase();
            
            options.forEach(option => {
                const value = option.textContent.toLowerCase();
                const display = value.indexOf(searchText) === -1 ? 'none' : 'block';
                option.style.display = display;
            });
      
            select.dropdown.recalculateDimensions();
        }
      
        // Function to give keyboard focus to the search input field
        function focusSearchBox() {
      searchBox.querySelector('input').focus();
        }
      
        select.dropdown.options.autoFocus = false;
      
        if (window.matchMedia('(hover: hover) and (pointer: fine)').matches) {
            select.input.addEventListener('click', focusSearchBox);
            options.forEach(option => {
                option.addEventListener('click', focusSearchBox);
            });
        }
        searchBox.addEventListener('keyup', filterOptions);
      });
    }
  openPopup() {
    this.isMobileVerify = websiteSettings.data.imv;
    this.isB2C = websiteSettings.data.isB2C;
    this.isManualDeposit = websiteSettings.data.sdbo == ShowDepositButtionOption.Both || websiteSettings.data.sdbo == ShowDepositButtionOption.Manual;
    this.isRechargeDeposit = websiteSettings.data.sdbo == ShowDepositButtionOption.Both || websiteSettings.data.sdbo == ShowDepositButtionOption.Recharge;
    if(!this.isMobileVerify){
    this.mobileno = websiteSettings.data.mno;
    this.mobilenoshow = this.mobileno;
    var mobilenum = this.mobilenoshow;
    var numb = mobilenum.slice(2, 12);
    this.mobilenoshow = numb;
    this.ResendOTPCount = apiEndPointData.data.ResendOTPLimit;
    }

    if(this.isMobileVerify){
    this.GetDWLimitResults();
    this.model = { option: 'deposit' };
    // this.KYCStatus = '';
    this.documentImg = '';
    // this.isUpdateKYC = false;
    this.documentType = 1;
    this.getDocumentName();
    this.withdrawForm.reset();
    this.depositForm.reset();
    this.KYCForm.reset();
    this.modalInstances.open();
    this.GetKycStatus();
    } else {
    this.otpVerifyModalInstances.open();
    }
  }
  close() {
    this. model = { option: 'deposit' };
    this.KYCStatus = '';
    this.documentImg = '';
    this.isUpdateKYC = false;
    this.documentType = 1;
    this.getDocumentName();
    this.withdrawForm.reset();
    this.depositForm.reset();
    this.KYCForm.reset();
    this.modalInstances.close();
  }
  onDWItemChange(value){
    if (this.model.option === 'withdraw') {
      this.initSelect();
    }
 } 
  ondocumentTypeChange(val: any) {
    if(this.documentType == val){
      this.isdocumentcheck = true;
    }
    this.getDocumentName();
  }
  getDocumentName() {
    const doc = this.documentTypes.find(x => x.kmi == this.documentType);
    this.documentName = doc ? doc.nm :'';
  }
  updateKyc() {
      this.b2cUserService.GetKycDocument().pipe(catchError(err => throwError(err))).subscribe((reponse: any) => {
      if (reponse.message) {
        this.documentTypes = reponse.message;
        this.KYCForm.reset();
        this.isUpdateKYC = true;
        this.documentImg = '';
        this.getDocumentName();
        this.initSelect();
        setTimeout(() => {
          this.initSelect();
        }, 500);
      }
    }, err => console.log('GetKycDocument', err));
  }
  GetKycStatus() {
    this.b2cUserService.GetKycStatus().pipe(catchError(err => throwError(err))).subscribe((reponse: any) => {
      if (reponse.message) {
        this.KYCStatus = reponse.message.sts;
        this.DeclineKYCStatus = reponse.message.rm;
        if(reponse.message.sts === 'Declined'){
          this.getdocumentImg = reponse.message.dp;
          this.documentno = reponse.message.dn;
         this.documentname = reponse.message.ed;
        }
        if(reponse.message.sts === 'Approved'){
         this.GetBankDetail();
         this.GetbankList();
        }
      }
    }, err => console.log('GetKycStatus', err));
  }
  GetDWLimitResults() {
    this.b2cUserService.GetDWLimitResult().pipe(catchError(err => throwError(err))).subscribe((reponse: any) => {
      if(reponse.message){
      this.Amountminmax = reponse.message;
      }
    }, err => console.log('GetDWLimitResults', err));
  }
  depositRequest() {
    if(!this.isdepositminmax){
    if (this.depositForm.valid) {
      this.loading = true;
      this.b2cUserService.depositAmount(this.depositForm.value).pipe(catchError(err => throwError(err))).subscribe((reponse: any) => {
        this.loading = false;
        if (reponse.isSuccess) {
          window.open(reponse.result.message, '_blank');
          this.close();
          this.dsmodalInstances.open();
        } else {
          this.toastr.error(reponse.result.message);
        }
      }, err => {this.loading = false;console.log('depositRequest', err)});
    } else {
      this.loading = false;
      Object.keys(this.depositForm.controls).forEach(field => {
        const control = this.depositForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
    }
  }
  withdrawRequest() {
    if(!this.iswithdrawminmax){
    if (this.withdrawForm.valid) {
      if(this.withdrawForm.value.bankDetailId != 0){
      this.loading = true;
      this.b2cUserService.WithdrawRequest(this.withdrawForm.value).pipe(catchError(err => throwError(err))).subscribe((reponse: any) => {
        this.loading = false;
        if (reponse.isSuccess) {
          this.toastr.success(reponse.result.message);
          this.modalInstances.close();
        } else {
          this.toastr.error(reponse.result.message);
        }
      }, err =>{this.loading = false;console.log('withdrawRequest', err)});
    }
    } else {
      this.loading = false;
      Object.keys(this.withdrawForm.controls).forEach(field => {
        const control = this.withdrawForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }
  }
  KYCRequest() {
    if(!this.isdocumentcheck){
    if (this.KYCForm.valid) {
     this.KYCForm.value.documentNo = this.KYCForm.value.documentNo.replace(/\s/g, '');
     if(this.KYCForm.value.documentNo.length > 0){
      this.isRequiredDocumentCtrl = false;
      this.loading = true;
      this.b2cUserService.UpdateKycDocument(this.KYCForm.value).pipe(catchError(err => throwError(err))).subscribe((reponse: any) => {
        this.loading = false;
        if (reponse.isSuccess) {
          this.toastr.success(reponse.result.message);
          this.close();
        } else {
          this.toastr.error('Valid Information Added');
        }
      }, err => { this.loading = false;console.log('KYCRequest', err) });
    }
    } else {
      this.loading = false;
      this.isRequiredDocumentCtrl =  !this.KYCForm.controls["document"].valid;
      Object.keys(this.KYCForm.controls).forEach(field => {
        const control = this.KYCForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
   }
  }
  validationdocument(event: any){
    event.target.value  = event.target.value.replace(/\s/g, '');
    for (let index = 0; index < this.documentTypes.length; index++) {
      const element = this.documentTypes[index];
       if(element.nm == this.documentName){
         if(event.target.value.length >= element.min && event.target.value.length <= element.max){
          this.isdocumentcheck = false;
         } else {
         this.isdocumentcheck = true;
         }
       }
    }
  }
  addBankDetailRequest() {
    if (this.addBankDetailForm.valid) {
      this.holdername = this.addBankDetailForm.value.accountHolderName.replace(/\s/g, '');
      this.bankname = this.addBankDetailForm.value.bankName.replace(/\s/g, '');
      this.accountno = this.addBankDetailForm.value.accountNo.replace(/\s/g, '');
      this.ifsccode = this.addBankDetailForm.value.ifsc.replace(/\s/g, '');
     if(this.holdername.length > 0 && this.bankname.length > 0 && this.accountno.length > 0 && this.ifsccode.length > 0){
      this.loading = true;
      this.b2cUserService.AddBankDetail(this.addBankDetailForm.value).pipe(catchError(err => throwError(err))).subscribe((reponse: any) => {
        this.loading = false;
        if (reponse.isSuccess) {
          this.toastr.success(reponse.result.message);
          this.isBankDetail = true;
          this.isUpdateKYC = false;
          this.isAddBankDetail = !this.isAddBankDetail;
          this.addBankDetailForm.reset();
          this.withdrawForm.reset();
          this.GetBankDetail();
        } else {
          this.toastr.error(reponse.result.message);
        }
      }, err => { this.loading = false;console.log('addBankDetailRequest', err) });
    }
    } else {
      this.loading = false;
      Object.keys(this.addBankDetailForm.controls).forEach(field => {
        const control = this.addBankDetailForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }
  GetBankDetail() {
    this.b2cUserService.GetBankDetail().pipe(catchError(err => throwError(err))).subscribe((reponse: any) => {
      if (reponse.message) {
        this.bankDetails = reponse.message;
      }
      this.initSelect();
      setTimeout(() => {
        this.initSelect();
      }, 500);
    }, err => console.log('GetBankDetail', err));
  }
  onOKClick() {
    this.dsmodalInstances.close();
  }
  onAddBankDeatailBtnClick(value) {
    this.isBankDetail = value;
    this.isAddBankDetail = !this.isAddBankDetail;
    this.isUpdateKYC = !value;
    this.addBankDetailForm.reset();
    this.withdrawForm.reset();
    this.initSelection();
      setTimeout(() => {
        this.initSelection();
      }, 500);

      this.initSelect();
      setTimeout(() => {
        this.initSelect();
      }, 500);
  }
  GetbankList(){
    this.b2cUserService.GetBankListDetail().pipe(catchError(err => throwError(err))).subscribe((reponse: any) => {
      if (reponse.message) {
        this.bankList = reponse.message;
      }
      this.initSelection();
      setTimeout(() => {
        this.initSelection();
      }, 500);
    }, err => console.log('GetBankListDetail', err));
  }
  EditNumber(){
    this.isEditNumber = true;
    // this.PhoneinitSelection();
  }
  CancelNumber(){
    this.isEditNumber = false;
  }
  timer(remaining) {
    let ms = Math.floor(remaining / 60);
    let sm = remaining % 60;
    
    let mm = ms < 10 ? '0' + ms : ms;
    let ss = sm < 10 ? '0' + sm : sm;
    this.OTPtimer = mm + ':' + ss;
    remaining -= 1;
    
    if(remaining >= 0 && this.timerOn) {
      setTimeout(() => {
        this.timer(remaining);
        }, 1000);
      return;
    }
    // Do timeout stuff here
    this.otpAgainDisabled = false;
    this.otptimershow = false;
  }
  sendOTPRequest() {
    // let MobileNo = this.mobileno.replace(/\D/g, '');
    var number: string;
    if(this.isEditNumber){
      if(this.mobilenoshow != null && this.mobilenoshow != ''){
        const mobileNo = this.mobilenoshow.dialCode + '' + this.mobilenoshow.number;
        number = mobileNo.replace(/\D/g, '');
      } else{
        this.numberrequiredvalidation = true;
      }
    } else {
      number = this.mobileno.replace(/\D/g, '');
    }
    if (!this.numbervalidation  && !this.numberrequiredvalidation) {
      if (this.FPResendOTPCount === this.ResendOTPCount) {
        this.otpAgainDisabled = true;
      } else {
    this.loading = true;
    this.b2cUserService.DPResendOTPRequest(number).pipe(catchError(err => throwError(err))).subscribe((reponse: any) => {
      this.loading = false;
      if (reponse.isSuccess) {
        this.toastr.success(reponse.result.message);
        // localStorage.setItem('verification_token', JSON.stringify(reponse.result.token));
        this.isotpformopen = true;
        if(this.isEditNumber){
          var num = number.slice(number.length - 2);
          var numer = number.slice(2, 4);
          this.isMobileUser = numer + 'xxxxxx' + num;
        } else {
          var nums = this.mobileno.slice(this.mobileno.length - 2);
          var numers = this.mobileno.slice(2, 4);
          this.isMobileUser = numers + 'xxxxxx' + nums;
        }
        
        this.FPResendOTPCount++;
        this.otptimershow = true;
        this.timer(60);
        this.otpAgainDisabled = true;
      } else {
        this.toastr.error(reponse.result.message);
      }
    }, err => {this.loading = false;console.log('verifyRegistrationRequest', err)});
    }
  }
  }
  
  otpVerificationClick() {
    var number: string;
    if(this.isEditNumber){
      const mobileNo = this.mobilenoshow.dialCode + '' + this.mobilenoshow.number;
      number = mobileNo.replace(/\D/g, '');
    } else {
      number = this.mobileno.replace(/\D/g, '');
    }
    const data = Object.values(this.otpVerifyForm.value)
    let OTP = data[0] + '' + data[1] + '' + data[2] + '' + data[3];
   
    if (this.otpVerifyForm.valid) {
    this.loading = true;
    this.b2cUserService.VerifyResendOTPRequest(number, OTP).pipe(catchError(err => throwError(err))).subscribe((reponse: any) => {
      this.loading = false;
      if (reponse.isSuccess) {
        this.toastr.success(reponse.result.message);
        this.isMobileVerify = true;
        this.otpVerifyModalInstances.close();
      } else {
        this.toastr.error(reponse.result.message);
      }
    }, err => {this.loading = false;console.log('verifyRegistrationRequest', err)});
    }
  }
  reSendOtpClick() {
    var number: string;
    if(this.isEditNumber){
      const mobileNo = this.mobilenoshow.dialCode + '' + this.mobilenoshow.number;
      number = mobileNo.replace(/\D/g, '');
    } else {
      number = this.mobileno.replace(/\D/g, '');
    }
      if (this.ResendOTPRemainCount === this.ResendOTPCount) {
        this.otpAgainDisabled = true;
      } else {
    this.b2cUserService.DepositResendOTPRequest(number).pipe(catchError(err => throwError(err))).subscribe((reponse: any) => {
      if (reponse.isSuccess) {
        this.toastr.success(reponse.result.message);
        this.ResendOTPRemainCount++;
        this.otptimershow = true;
        this.timer(120);
        this.otpAgainDisabled = true;
      } else {
        this.toastr.error(reponse.result.message);
      }
    }, err => {this.loading = false;console.log('verifyRegistrationRequest', err)});
    }
  }
  dpotpclose(){
    this.otpVerifyModalInstances.close();
  }
  GetCampaignList() {
    this.bonusService.getCampaignList().pipe(catchError(err => throwError(err))).subscribe((reponse: any) => {
      if (reponse.isSuccess) {
      }
    }, err => console.log('GetCampaignList', err));
  }

  GetWalletList() {
    this.bonusService.getWalletList().pipe(catchError(err => throwError(err))).subscribe((reponse: any) => {
      if (reponse.isSuccess) {
      }
    }, err => console.log('GetWalletList', err));
  }

  UpdateWallet() {
    let WalletId = 0;
    this.bonusService.updateWalletRequest(WalletId).pipe(catchError(err => throwError(err))).subscribe((reponse: any) => {
      if (reponse.isSuccess) {
        }
    }, err => {this.loading = false;console.log('UpdateWalletList', err)});
  }
}

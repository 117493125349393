import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { take, catchError, map } from 'rxjs/operators';

import { BaseRequest } from '@clientApp-core/models/base/base-request.model';
import { HttpClient } from '@angular/common/http';
import { apiEndPointData } from '../config/connfig.service';

export class NewsRequest implements BaseRequest<any> {
    constructor(public body: any, public queryParameter: any, public endPoint: string, public baseUrl: string = null) {}
 }

 export function mapNewsRequest(): BaseRequest<any> {
    const request = new NewsRequest({}, {}, mapEndpoint(''));
    return request;
}

export function mapEndpoint(endPoint: any): string {
    const endPointUrl = 'api/Account/News';
    return endPointUrl;
}

@Injectable()
export class NewsFacadeService {
    constructor(private httpClient: HttpClient) {
        }

    getNews$(): Observable<any> {
       const newsRequest = mapNewsRequest();
        return this.httpClient
        .get(this.getBaseUrl(newsRequest), newsRequest.body)
        .pipe(map((data: any) => { return data.result }),take(1), catchError(err => { return throwError(err) }));
    }
    getBaseUrl(item: any): string {
        let baseUrl: string;
        if (item.baseUrl) {
          baseUrl = `${item.baseUrl}${item.endPoint}`;
        } else {
          baseUrl = `${apiEndPointData.data.cau}${item.endPoint}`;
        }
        return baseUrl;
      }
}



import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject, of, throwError } from 'rxjs';
import { Stake } from '@clientApp-core/models/bet/stake.model';
import { EstimatedProfitLoss } from '@clientApp-core/models/bet/estimated-profit-loss.model';
import * as fromBet from './bet.service.helper';
import { PlaceBet } from '@clientApp-core/models/bet/place-bet.model';
import { switchMap, take, catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { apiEndPointData } from '../config/connfig.service';



@Injectable()
export class BetFacadeService {

    private _placedBetSubject = new Subject<any>();
    private _setStakeSubject = new Subject<Stake>();
    private _balanceSubject = new Subject<any>();
    private _liabilitySubject = new Subject<any>();
    private _OverlayPanelSubject = new Subject<boolean>();
    private _deleteAllBetSubject = new Subject<any>();
    private _deleteBetSubject = new Subject<any>();
    private _estimatedProfitLossSubject = new BehaviorSubject<any>(null);
    private _isPlaceBetClicked: boolean;
    private _SelectedRuner = new Subject<string>();
    private _setBetIdSubject = new Subject<number>();
    selectedBetDetailId: number;
    lastSelectedItem: string;

    constructor(private httpClient: HttpClient) { }

    getStake$(): Observable<Stake> {
        return this._setStakeSubject.asObservable();
    }

    setStake(): Subject<Stake> {
        return this._setStakeSubject;
    }

    getEstimatedProfitLoss(): Observable<EstimatedProfitLoss[]> {
        return this._estimatedProfitLossSubject.asObservable();
    }

    sendEstimatedProfitLoss(): BehaviorSubject<EstimatedProfitLoss[]> {
        return this._estimatedProfitLossSubject;
    }

    getBetStatus(): boolean {
        return this._isPlaceBetClicked;
    }

    setBetStatus(value: boolean) {
        this._isPlaceBetClicked = value;
    }


    getOvelayStatus$(): Observable<boolean> {
        return this._OverlayPanelSubject.asObservable();
    }

    setOvelayStatus(): Subject<boolean> {
        return this._OverlayPanelSubject;
    }
    getSelectedRunner$(): Observable<string> {
        return this._SelectedRuner.asObservable();
    }

    setSelectedRunner(): Subject<string> {
        return this._SelectedRuner;
    }
    getBetId$(): Observable<number> {
        return this._setBetIdSubject.asObservable();
    }

    setBetId(): Subject<number> {
        return this._setBetIdSubject;
    }


    getBalance(): void {
        const balanceRequest = fromBet.mapBalanceBetRequest();
        this.httpClient.get(this.getBaseUrl(balanceRequest), { params: balanceRequest.queryParameter }).pipe(map((data: any) => { return data.result }), switchMap((resp: any) => {
            return of({
                balance: resp.balance,
                liability: resp.liability
            });
        }), take(1), catchError(err => throwError(err)))
            .subscribe(
                value => this._balanceSubject.next(value),
                err => console.log('getBalance', err)
            );

    }

    checkBalance$(): Observable<any> {
        return this._balanceSubject.asObservable();
    }

    getLiability(): void {
        const liabilityRequest = fromBet.mapLiabilityBetRequest();
        this.httpClient.get(this.getBaseUrl(liabilityRequest), {params:liabilityRequest.queryParameter})
            .pipe(map((data: any) => { return data.result }),take(1), catchError(err => throwError(err)))
            .subscribe(
                (response: any) => this._liabilitySubject.next(response),
                err => console.log('getLiability', err)
            );
    }

    checkLiability(): Observable<any> {
        return this._liabilitySubject.asObservable();
    }

    getBaseUrl(item: any): string {
        let baseUrl: string;
        if (item.baseUrl) {
            baseUrl = `${item.baseUrl}${item.endPoint}`;
        } else {
            baseUrl = `${apiEndPointData.data.cau}${item.endPoint}`;
        }
        return baseUrl;
    }
}



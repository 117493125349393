import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BetFacadeService } from '@clientApp-core/services/bet/bet.facade.service';
import { CommonService } from '@clientApp-core/services/common/common.service';
import { DeviceInfoService } from '@clientApp-core/services/device-info/deviceinfo.services';
import { SessionService } from '@clientApp-core/services/session/session.service';
import { StoreService } from '@clientApp-core/services/store/store.service';
import { Store } from '@ngrx/store';
import * as M from "materialize-css/dist/js/materialize";
import { apiEndPointData } from '@clientApp-core/services/config/connfig.service';
import { Stake } from '@clientApp-core/models/bet/stake.model';
import { ToastrService } from 'ngx-toastr';
import { NodeCentralService } from '@clientApp-core/services/session/node-central.service';
import { GuestMLConfig, websiteSettings } from '@clientApp-core/services/authentication/authentication-facade.service';
import { mapUniqueData } from '@clientApp-core/services/shared/JSfunction.service';
import { SharedModule } from '@clientApp-shared/shared.module';

declare var $: any;

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit,OnChanges,OnDestroy {
  @Input('searchString') searchString: string;
  @Output() valueChange = new EventEmitter();
  @ViewChild('search', { static: true }) searchModel: ElementRef;
  modalInstances: any;
  searchStr: string;
  groupedMarketsList: any = [];
  user: any;
  websitesettings: any;
  constructor( public betService: BetFacadeService,
    public router: Router, private sessionService: SessionService, private toastr: ToastrService,
    public storeService: StoreService, private commonService: CommonService,private nodeCentralService: NodeCentralService,
    private deviceInfoService: DeviceInfoService) { }

  ngOnInit(): void {
    this.modalInstances = M.Modal.init(this.searchModel.nativeElement, {});
    this.user = JSON.parse(localStorage.getItem('token'));
  }
  ngOnChanges() {
    // this.searchMatch(this.searchString);
  }

  openPopup() {
    this.searchStr = '';
    this.groupedMarketsList = [];
    this.modalInstances.open();
    $('#searchstr').focus();
    this.user = JSON.parse(localStorage.getItem('token'));
  }
  hideSearchModal() {
      this.groupedMarketsList = [];
      this.searchStr = '';
      this.modalInstances.close();
  }
  searchMatch(searchStr) {
  this.commonService.searchget(searchStr);
  }
  ngOnDestroy(){}
}

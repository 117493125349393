import { Component, OnInit } from '@angular/core';
import { apiEndPointData } from '@clientApp-core/services/config/connfig.service';
import { SharedModule } from '@clientApp-shared/shared.module';
import { MaintenanceComponent } from "../../maintenance/maintenance.component";

@Component({
    selector: 'app-login-layout',
    standalone: true,
    templateUrl: './login-layout.component.html',
    styleUrls: ['./login-layout.component.scss'],
    imports: [SharedModule, MaintenanceComponent]
})
export class LoginLayoutComponent implements OnInit {
  inMaintenance: boolean;
  constructor() { }

  ngOnInit(): void {
    this.inMaintenance = apiEndPointData.data.inMaintenance;
  }

}

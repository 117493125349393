import { Component, OnInit } from '@angular/core';
import { CommonService } from '@clientApp-core/services/common/common.service';
import { apiEndPointData } from '@clientApp-core/services/config/connfig.service';
import { SharedModule } from '@clientApp-shared/shared.module';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  siteName: string;
  whatsappNumber: string;
  telegramLink: string;
  supportMail: string;
  currentYear: any = '';
  constructor(public commonService: CommonService) { }

  ngOnInit(): void {
    this.whatsappNumber = apiEndPointData.data.wan;
    this.telegramLink = apiEndPointData.data.tl;
    this.supportMail = apiEndPointData.data.sm;
    this.siteName = window.location.origin;
    let date = new Date();
    this.currentYear = date.getFullYear();
  }

}

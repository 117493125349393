<div id="liability" class="modal modal-fixed-footer" #liability>
  <div class="modal-content">
    <a href="javascript:void(0)" (click)="hideModal()" class="modal-close right"><i class="fa fa-times" aria-hidden="true"></i></a>
    <h5>Liability</h5>
    <div class="modal-body">
      <div class="liability-table">
        <table class="table highlight">
          <thead>
            <tr>
              <th colspan="1">Date</th>
              <th colspan="1">Market</th>
              <th colspan="2" class="center-align">Liability</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of liabilityData;trackBy:identify1;">
              <tr>
                <td>{{item.appDate | date:'dd-MM-yyy hh:mm a'}}</td>
                <td>
                  <a class="cursor-pointer" *ngIf="item.appTournament != null && item.appMatch != null" (click)="onMarketSelect(item)">
                    {{item.appSport}} - {{item.appTournament}} - {{item.appMatch}}
                  </a>
                  <a  *ngIf="item.appTournament == null && item.appMatch == null" (click)="onMarketSelect(item)">
                    {{item.appSport}}
                  </a>
                </td>
                <td>
                </td >
                <td style="text-align:right">{{item.totalLiability}}</td>
              </tr>
              <ng-container *ngFor="let data of item.liability;trackBy:identify;">
              <tr *ngIf="data.appBetID !== 0">
                <td></td>
                <td>
                  <a>
                    {{data.appbetname}}
                  </a>
                </td>
                <td style="text-align:right; font-weight: 500;">
                  {{data.appLiability}}
                </td >
                <td ></td>
              </tr>
            </ng-container>
            </ng-container>
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td style="text-align:right">Total Liability</td>
              <td></td>
              <td style="text-align:right">{{totalLiability | number:'1.2-2'}}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <a href="javascript:void(0)" (click)="hideModal()" class="modal-close red btn"><span class="animate-btn">Close</span></a>
  </div>
</div>
import { Component, HostListener, ViewChild, TemplateRef, OnInit, Inject, Renderer2, AfterViewInit } from '@angular/core';
import { NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Router, Event } from '@angular/router';
import { Store } from '@ngrx/store';
import { CommonService } from '@clientApp-core/services/common/common.service';
import { MarketRateFacadeService } from '@clientApp-core/services/market/market-rates/market-rate-facade.service';
import { SessionService } from '@clientApp-core/services/session/session.service';
import { AuthFacadeService } from '@clientApp-core/services/authentication/authentication-facade.service';
import { MarketFacadeService } from '@clientApp-core/services/market/market-facade.service';
import { NodeCentralService } from '@clientApp-core/services/session/node-central.service';
import { catchError, take } from 'rxjs/operators';
import { fromEvent, throwError } from 'rxjs';
import { ConnectionState } from '@clientApp-core/enums/connectionState-type';
import { DOCUMENT } from '@angular/common';
import { apiEndPointData } from '@clientApp-core/services/config/connfig.service';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
declare var $:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'app';
  loading = false;
  status = 'ONLINE';
  isConnected = true;
  uiTheme: any;
  cpuWorker: any;
  // favoritegameslist: any = [];
  favIcon: HTMLLinkElement = window.document.querySelector('#appIcon');
  @ViewChild('template', { static: true }) template: TemplateRef<any>;
  constructor(@Inject(DOCUMENT) private document: Document, private router: Router
    , private commonService: CommonService
    , private marketRateFacadeService: MarketRateFacadeService
    , private sessionService: SessionService
    , private _authService: AuthFacadeService
    , private marketFacadeService: MarketFacadeService
    , private nodeCentralService: NodeCentralService
    , private titleService: Title
    , private renderer: Renderer2
    , private toastr: ToastrService) {
      this.intializeWorker();
    router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        if (!router.navigated && routerEvent.url.indexOf('/login') === -1) {
          this._authService.IsAuthorized$().pipe(take(1)).subscribe((data: any) => {
            if (data.isNewToken) {
              const user = JSON.parse(localStorage.getItem('token'));
              if (user != null) {
                user.access_token = data.token;
                user.expireOn = data.expireOn;
                localStorage.setItem('token', JSON.stringify(user));
              }
            }
          }, error => console.warn('User is unauthorized please login', error));
        }
      }
      window.scrollTo(0, 0);
      this.checkRouterEvent(routerEvent);
    });
    this.isConnected = window.navigator.onLine;
    fromEvent(window, 'online').subscribe(e => {
      this.toastr.error('Make sure your device is connected to the internet.', 'No Internet Connection', {
        closeButton: false,
        progressBar: false,
        timeOut: 0,
        tapToDismiss: false
      });
      console.log('ONLINE');
    });
    fromEvent(window, 'offline').subscribe(e => {
      this.isConnected = false;
      console.log('OFFLINE');
    });
  }
  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
  }
  @HostListener("window:visibilitychange", ["$event"])
  onVisibilityChange($event) {
    const isVisible = $event.target.visibilityState === 'visible';
    if (isVisible) {
    } else {
      // tab is not-visible
    }
  }
  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
  }
  checkRouterEvent(routerEvent: Event): void {
    if (routerEvent instanceof NavigationStart) {
      this.loading = true;
      this.commonService.setLoadingStatus(true);
    }
    if (routerEvent instanceof NavigationEnd
      || routerEvent instanceof NavigationCancel
      || routerEvent instanceof NavigationError) {
      this.loading = false;
      this.commonService.setLoadingStatus(false);
    }
  }
  renderExternalScript(src: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.body, script);
    return script;
  }
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  ngOnInit() {
    this.uiTheme = apiEndPointData.data.themes ? apiEndPointData.data.themes : null;
    this.favIcon.href = this.uiTheme ? this.uiTheme.fic : '';
    const origin = apiEndPointData.data.titleName ? apiEndPointData.data.titleName : window.location.host;
    let url = origin.lastIndexOf(".") > -1 ? origin.substring(0, origin.lastIndexOf(".")) : origin;
        url = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
    this.setTitle(this.capitalizeFirstLetter(url));
    var commonCssPath = apiEndPointData.data.commonCssPath;
    for (var c = 0; c < commonCssPath.length; c++) {
      this.loadStyle(apiEndPointData.data.commonContentPath + commonCssPath[c]);
    }
    var themeCssPath = apiEndPointData.data.themeCssPath;
    for (var c = 0; c < themeCssPath.length; c++) {
      // this.loadStyle(apiEndPointData.data.themeBasePath + themeCssPath[c]);
         this.loadStyle(themeCssPath[c]);
    }
  }
  ngAfterViewInit() {
    $(window).scroll(function(){
      if ($(this).scrollTop() > 100) {
          $('.scrollToTop').fadeIn();
      } else {
          $('.scrollToTop').fadeOut();
      }
    });
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    const style = this.document.createElement('link');
    style.id = 'client-theme';
    style.rel = 'stylesheet';
    style.href = `${styleName}`;

    head.appendChild(style);

  }
  scrollToTop() {
    $('html, body').animate({scrollTop : 0},100);
  }
  intializeWorker() {
    if (typeof Worker !== 'undefined') {
      if (!this.cpuWorker) {
        this.cpuWorker = new Worker(new URL('./worker/cpu.worker', import.meta.url),
          { type: "module" });
      }
    } else {
    }
  }
  
}


import { Injectable } from '@angular/core';
import { Observable, Subject, ReplaySubject, throwError, BehaviorSubject } from 'rxjs';
import { take, catchError, map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Match } from '@clientApp-core/models/market/match.model';
import { SessionService } from '@clientApp-core/services/session/session.service';
import { MarketRates } from '@clientApp-core/models/market/market-rates.model';
import { BetSerializer } from '@clientApp-core/serializers/market/market-bet-details.serializer';
import { MarketBet } from '@clientApp-core/models/market/market-bet.model';
import { StoreService } from '@clientApp-core/services/store/store.service';
import { MarketFacadeService } from '@clientApp-core/services/market/market-facade.service';
import { AuthFacadeService } from '@clientApp-core/services/authentication/authentication-facade.service';
import { MarketRunner } from '@clientApp-core/models/market/market-runner.model';
import { MarketCashout } from '@clientApp-core/models/market/market-cashout.model';
import { AudioType } from '@clientApp-core/enums/audio.types';
import { HttpClient } from '@angular/common/http';
import { apiEndPointData } from '@clientApp-core/services/config/connfig.service';
import { ActiveMarket } from '@clientApp-core/models/market/activemarket.model';

@Injectable()
export class MarketRateFacadeService {

  private _matchSubject = new Subject<any>();
  private _runningDashboardMarketSubject = new ReplaySubject(2);
  private _runningMarketSubject = new ReplaySubject(4);
  private _centralScoreSubject = new ReplaySubject(1);
  private _adhocMatchSubject = new Subject<any>();
  private _marketCommentarySubject = new Subject<any>();
  private _marketBetListSubject = new Subject<any>();
  private _currentMarketSubject = new Subject<any>();
  private _matchWiseMarketSubject = new Subject<any>();
  private _currentMarketVolumeSubject = new BehaviorSubject<any>(null);
  private _currentMarketRunnerSubject = new BehaviorSubject<any>(null);
  private  _marketBetAllowChanges = new Subject<any>();
  private  _marketRateVolumeChanges = new Subject<any>();
  private  _inPlayChanges = new Subject<any>();
  private  _marketStatusChanges = new Subject<any>();
  private  _multiMatchWiseInfo = new Subject<any>();
  private  _marketChanges = new Subject<any>();
  private _marketCashoutSubject = new BehaviorSubject<any>(null);
  private _placedBetCashoutSubject = new Subject<any>();
  private _matchBetCountSubject = new Subject<number>();
  private _unMatchBetCountSubject = new Subject<number>();
  private _setAudioTypeSubject = new Subject<AudioType>();
  private _addNewMarketSubject = new Subject<any>();
  private _clientLimitChangesSubject = new Subject<any>();
  private _marketLimitChangesSubject = new Subject<any>();
  private _getBetListSubject = new Subject<any>();
  private _getMarketClientBetListSubject = new Subject<any>();
  private _deleteBetListSubject = new Subject<any>();
  private _marketNewsChangesSubject = new ReplaySubject(1);
  private _ewclChangesSubject = new Subject<any>();
  private _mwclLimitChangesSubject = new Subject<any>();
  curMatchInfo: Match[] = [];
  curMarketsVol: MarketRates[] = [];
  curMarketsRate: MarketRates[] = [];
  curMarketsRunners: MarketRunner[] = [];
  marketCashout: MarketCashout[] = [];
  marketScore: any[] = [];
  clientLimit: any;

  constructor(private httpClient: HttpClient
    , private marketFacadeService: MarketFacadeService
    , private sessionService: SessionService
    , private _authService: AuthFacadeService
    , private storeService: StoreService) {
    this.init();
  }
  getewclChanges$(): Observable<any> {
    return this._ewclChangesSubject.asObservable();
  }
  getmwclChanges$(): Observable<any> {
    return this._mwclLimitChangesSubject.asObservable();
  }
  getClientLimitChanges$(): Observable<any> {
    return this._clientLimitChangesSubject.asObservable();
  }
  getMarketLimitChanges$(): Observable<any> {
    return this._marketLimitChangesSubject.asObservable();
  }
  getBetList$(): Observable<any> {
    return this._getBetListSubject.asObservable();
  }
  deleteBetList$(): Observable<any> {
    return this._deleteBetListSubject.asObservable();
  }
  setMarketClientBetList(): Subject<any> {
    return this._getMarketClientBetListSubject;
  }
  getMarketClientBetList$(): Observable<any> {
    return this._getMarketClientBetListSubject.asObservable();
  }
  getMarketBetAllowChanges$(): Observable<any> {
    return this._marketBetAllowChanges.asObservable();
  }
  getMarketRateVolumeChanges$(): Observable<any> {
    return this._marketRateVolumeChanges.asObservable();
  }
  getMultiMatchWiseInfo$(): Observable<any> {
    return this._multiMatchWiseInfo.asObservable();
  }
  getMarketChanges$(): Observable<any> {
    return this._marketChanges.asObservable();
  }
  getInPlayChanges$(): Observable<any> {
    return this._inPlayChanges.asObservable();
  }
  getMarketStatusChanges$(): Observable<any> {
    return this._marketStatusChanges.asObservable();
  }
  getRunningDashboardMarketDetail$(): Observable<any> {
    return this._runningDashboardMarketSubject.asObservable();
  }
  getRunningMarketDetail$(): Observable<any> {
    return this._runningMarketSubject.asObservable();
  }
  getCentralScoreDetail$(): Observable<any> {
    return this._centralScoreSubject.asObservable();
  }
  getMarketCommentary$(): Observable<any> {
    return this._marketCommentarySubject.asObservable();
  }
  getAddNewMarketInfo$(): Observable<any> {
    return this._addNewMarketSubject.asObservable();
  }
  getAdhocMatchInfo$(): Observable<any> {
    return this._adhocMatchSubject.asObservable();
  }

  getMatchInfo$(): Observable<Match> {
    return this._matchSubject.asObservable();
  }

  getCurrentMarketVolume$(): Observable<MarketRates[]> {
    return this._currentMarketVolumeSubject.asObservable();
  }

  getCurrentMarketRunner$(): Observable<any> {
    return this._currentMarketRunnerSubject.asObservable();
  }

  
  getBetInfo$(): Observable<MarketBet[]> {
    return this._marketBetListSubject.asObservable();
  }

  getCurrentMarket$(): Observable<MarketRates[]> {
    // this.currentMarket();
    return this._currentMarketSubject.asObservable();
  }
  getMarketCashout(): Observable<MarketCashout> {
    return this._marketCashoutSubject.asObservable();
  }

  setMarketCashout(): BehaviorSubject<MarketCashout> {
    return this._marketCashoutSubject;
  }
  getMatchBetCount$(): Observable<number>  {
    return this._matchBetCountSubject.asObservable();
  }
  setMatchBetCount(): Subject<number>  {
      return this._matchBetCountSubject;
  }
  getUnMatchBetCount$(): Observable<number>  {
    return this._unMatchBetCountSubject.asObservable();
  }
  setUnMatchBetCount(): Subject<number>  {
      return this._unMatchBetCountSubject;
  }
  getAudioType$(): Observable<AudioType> {
    return this._setAudioTypeSubject.asObservable();
  }
  setAudioType(): Subject<AudioType> {
      return this._setAudioTypeSubject;
  }
  getMarketNewsChanges$(): Observable<any> {
    return this._marketNewsChangesSubject.asObservable();
  }


  checkCurrentLogin() {
    this._authService.IsAuthorized$().pipe(take(1)).subscribe((data:any) => {
      if (data.isAuthorized) {
        if (data.isNewToken) {
          const user = JSON.parse(localStorage.getItem('token'));
          if (user != null) {
           user.access_token = data.token;
           user.expireOn = data.expireOn;
           localStorage.setItem('token', JSON.stringify(user));
          }
        }
  } else {
    this.storeService.clearStore(); // logout
  }
    }, error => {this.storeService.clearStore();  console.log('checkCurrentLogin', error); } );
  }



getBaseUrl(item: any): string {
  let baseUrl: string;
  if (item.baseUrl) {
    baseUrl = `${item.baseUrl}${item.endPoint}`;
  } else {
    baseUrl = `${apiEndPointData.data.cau}${item.endPoint}`;
  }
  return baseUrl;
}
private init() {

  const centralMarketConnection$ = this.sessionService.centralHubConnection;

  this.sessionService.centralHubConnection.subscribe((connection) => {
    connection.on('DSRate', (markets: any) => {
      this._runningDashboardMarketSubject.next(markets);
    });
    connection.on('Rate', (markets: any) => {
      this._runningMarketSubject.next(markets);
    });
    // disconnectCommentary, getCommentary
    connection.on('connectCommentary', (commentary: any) => {
      this._marketCommentarySubject.next(commentary);
    });
  });

  const marketConnection$ = this.sessionService.marketHubConnection;
  // This trigger has used to  get event score
   marketConnection$.subscribe((connection) => {
   connection.on('getScore', (id: any, score: any) => {
     this._centralScoreSubject.next(score);
   });
  });
 // This trigger has used to change market bet allowed flag
  marketConnection$.subscribe((connection) => {
    connection.on('MarketBetAllowChanges', (data: any) => {
      this._marketBetAllowChanges.next(data);
    });
  });
  // This trigger has used to change market client limit setting
  marketConnection$.subscribe((connection) => {
    connection.on('ClientLimitChanges', (data: any) => {
      if (data !== null && data !== undefined && data.length > 0) {
        // console.log('ClientLimitChanges', data);
        const selectedMatches = localStorage.getItem('selected_matches');
        if (selectedMatches != null) {
          const matchids = JSON.parse(selectedMatches);
          this.sessionService.getewcl(matchids);
        }
        
        // const index = this.clientLimit.findIndex(x => x.appMarketType == data[0].appMarketType);
        // if (index !== undefined && index !== null && index >= 0) {
        //   this.clientLimit[index].appClientLimitMinStack = data[0].appClientLimitMinStack;
        //   this.clientLimit[index].appClientLimitMaxStack = data[0].appClientLimitMaxStack;
        //   this.clientLimit[index].appMaxProfit = data[0].appMaxProfit;
        //   this.clientLimit[index].au = data[0].au;
        //   this.clientLimit[index].cbr = data[0].cbr;
        //   this.clientLimit[index].clr = data[0].clr;
        //   this.clientLimit[index].ur = data[0].ur;
        // }
        // if (data[0].appIsMasterLimit === true) {
        //   this._clientLimitChangesSubject.next(data);
        // }
      }
    });
  });
  // This trigger has used to change market limit settings
  marketConnection$.subscribe((connection) => {
    connection.on('MarketLimitChanges', (data: any) => {
      // console.log('MarketLimitChanges', data);
      this.sessionService.getmwcl(data[0].appEventId);
      // this._marketLimitChangesSubject.next(data);
    });
  });
  // This trigger has used to update matched bet list TODO:: New
  marketConnection$.subscribe((connection) => {
    connection.on('GetBetList', (data: any) => {
      let betlistData = [], self = this;
        if (data !== null && data !== undefined) {
          if (Array.isArray(data)) {
            betlistData = data;
          } else {
            betlistData = [data];
          }
        }
        const clientBetlistData = betlistData.forEach(x => {
        if (x.appMatchID === null || x.appMatchID === undefined) {
          const runnerObj = self.curMarketsRunners.find(runner => x.rid === runner.rid);
            x.appMatchID = runnerObj ? runnerObj.eid : null;
          }
        })
      const betSerializer = new BetSerializer();
      const betList = betSerializer.fromJson(betlistData);
      this._getBetListSubject.next(betList);
    });
  });
  // This trigger has used to delete bet list :: New
  marketConnection$.subscribe((connection) => {
    connection.on('GetDeletedBetList', (data: any) => {
      let betlistData = [], self = this;
      if (data !== null && data !== undefined) {
        if (Array.isArray(data)) {
          betlistData = data;
        } else {
          betlistData = [data];
        }
      }
      const betSerializer = new BetSerializer();
      const betList = betSerializer.fromJson(betlistData);
      this._deleteBetListSubject.next(betList);
      
    });
  });
  // This trigger has used to change market volume amount
  marketConnection$.subscribe((connection) => {
    connection.on('MarketRateVolumeChanges', (data: any) => {
      this._marketRateVolumeChanges.next(data);
    });
  });
  // this trigger has used to change market acticve and in-active
  marketConnection$.subscribe((connection) => {
    connection.on('MarketChanges', (data: any) => {
      if (data && data.appIsActive !== undefined && !data.appIsActive) {
        this.marketFacadeService.removeMaket(data.appBetID);
      }
    });
  });
 // This trigger has used to change market In-Play flag
 marketConnection$.subscribe((connection) => {
  connection.on('InPlayChanges', (data: any) => {
    this._inPlayChanges.next(data);
  });
 });
 // This trigger has used to change market market status
 marketConnection$.subscribe((connection) => {
  connection.on('MarketStatusChanges', (data: any) => {
    this._marketStatusChanges.next(data);
  });
 });
 // This trigger has used to chnage following things
 // 1.is show video flag
 // 2.is Commentary on
 // 3.is channel no.
 // 4.id of Commentary
 marketConnection$.subscribe((connection) => {
  connection.on('MultiMatchWiseInfo', (data: any) => {
    this._multiMatchWiseInfo.next(data);
  });
 });
 marketConnection$.subscribe((connection) => {
  connection.on('MatchClientMarketList', (data: any) => {
  //   if (data !== null) {
  //     if (data !== undefined) {
  //       this._adhocMatchSubject.next(data);
  //     }
  // }
  });
 });

// This trigger has used to update market news
 marketConnection$.subscribe((connection) => {
  connection.on('MarketNews', (data: any) => {
    if (data !== null && data !== undefined) {
      let newsData = []
      if (Array.isArray(data)) {
        newsData = data;
      } else {
        newsData = [data];
      }
      this._marketNewsChangesSubject.next(newsData);
    }
 });
 });
// for match wise client Limit  get 
 marketConnection$.subscribe((connection) => {
  connection.on('ewcl', (data: any) => {
    // console.log('ewcl', data);
    this._ewclChangesSubject.next(data);
  });
 });
 // for Market wise client Limit  get : 
 marketConnection$.subscribe((connection) => {
  connection.on('mwcl', (data: any) => {
    // console.log('mwcl', data);
    this._mwclLimitChangesSubject.next(data);
  });
 });
 // This trigger has used to logout client from a website
 marketConnection$.subscribe((connection) => {
  connection.on('IsLogin', (data: any) => {
    this.checkCurrentLogin();
  });
 });
 marketConnection$.subscribe((connection) => {
  connection.on('LogOut', (data: any) => {
    this.checkCurrentLogin();
  });
 });
  }
 
 
  toLowercaseObjectKeys(myObj) {
    return Object.keys(myObj).reduce((prev, current) => 
    ({ ...prev, [current.toLowerCase()]: myObj[current]}), {})
  }
}



import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  AfterViewInit,
  OnChanges,
  Output,
  EventEmitter,
} from "@angular/core";
declare var $: any;
declare var _: any;
import { Store } from '@ngrx/store';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SessionService } from '@clientApp-core/services/session/session.service';
import { Stake } from '@clientApp-core/models/bet/stake.model';
import { StoreService } from '@clientApp-core/services/store/store.service';
import { BetFacadeService } from '@clientApp-core/services/bet/bet.facade.service';
import {Subscription, throwError } from 'rxjs';
import { DeviceInfoService } from '@clientApp-core/services/device-info/deviceinfo.services';
import { apiEndPointData } from '@clientApp-core/services/config/connfig.service';
import { CommonService } from '@clientApp-core/services/common/common.service';
import * as M from "materialize-css/dist/js/materialize";
import { AuthFacadeService, GuestMLConfig, casinoGameMenuSettings, websiteSettings, GuestCasinoConfig, } from "@clientApp-core/services/authentication/authentication-facade.service";
import { NodeCentralService } from "@clientApp-core/services/session/node-central.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { catchError, filter } from "rxjs/operators";
import {  leftmatchesBeforeLogin, mapMarket, mapMatch, removeAllGroup } from "@clientApp-core/services/shared/dashboard-shared.service";
import { MarketRateFacadeService } from "@clientApp-core/services/market/market-rates/market-rate-facade.service";
import { getInplayMarkets, mapUniqueData } from "@clientApp-core/services/shared/JSfunction.service";
import { ActiveMarket } from "@clientApp-core/models/market/activemarket.model";
import { SharedModule } from "@clientApp-shared/shared.module";

@Component({
  selector: "pb-left-sidebar",
  standalone: true,
  imports: [SharedModule],
  templateUrl: "./left.sidebar.component.html",
  styleUrls: ["./left.sidebar.component.css"],
})
export class LeftSidebarComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @Input() allMatches: ActiveMarket[];
  @Input() markets: any;
  @Input() isNewsExits: boolean;
  @Input() isCasinoGameOpen: boolean;
  @Output() openPopup = new EventEmitter();
  user: any;
  currentMenuIndex: number = 0;
  currentTournamentIndex: number = 0;
  excludeSport = [];
  excludeSports = [];
  excludeSportsMarket = [];
  excludeSportsList = [];
  sportList = [];
  upcomingMarkets = [];
  inPlayMarkets = [];
  isListwithTournament: boolean;
  websitesettings: any;
  selectedSport = "Home";
  activeAllSportsTab: boolean = true;
  activeInPlayTab: boolean = false;
  activeUpcomingTab: boolean = false;
  closesearch: boolean = false;
  selectedTab = "allsports";

  marketData: any;
  selecteitem: any = 0;
  selectprovider: any = 0;
  isDarkTheme: boolean = false;
  leftMatchesBeforeLogin: any;
  selectedGames: any = 'allgames';
  selectedProvider: any = 'allprovider';
  searchStr: string;
  casinoMenu = [];
  casinoGameList = [];
  providerList = [];
  casinoProviderItems:boolean[];
  All =
  {
    appCasinoGameID: 0,
    appDisplayName: "All Games",
    appDisplayOrder: 0,
    appGameName: "All Games",
    appIcon: apiEndPointData.data.commonContentPath + "CasinoGameType/all.webp",
    appIsActive: true
  };
  Provider =
  {
    appDisplayName: "All Provider",
    appDisplayOrder: 0,
    appIsActive: true,
    appIsNew: false,
    appProviderID: 0,
    appProviderLogo: apiEndPointData.data.commonContentPath + "casinoimg/all.webp",
    appProviderName: "All Provider",
    appDarkLogo: apiEndPointData.data.commonContentPath + "casinoimg/all-light.webp"
  }
  getCasinoOpenStatus: Subscription;
  matchArr = Array; 
  matchNum:number = 10;
  constructor(
    private router: Router,
    private authService: AuthFacadeService,
    private sessionService: SessionService,
    private storeService: StoreService,
    private betService: BetFacadeService,
    public commonService: CommonService,
    private deviceInfoService: DeviceInfoService,
    private nodeCentralService: NodeCentralService,
    private marketRateService: MarketRateFacadeService,
  ) {
    this.router.events.subscribe(val => {
      if (
        val instanceof NavigationEnd) {           
          if (val.urlAfterRedirects == '/live-casino') {
            this.selecteitem = 0;
            this.selectprovider = 0;
          }
      }
  });
  }

  ngOnInit() {
    this.getConfig();
    this.getGuestCasinoConfig();
    this.getCasinoConfig();
    const darkThemeCurrentState = this.commonService.getCookieValue('isCheckedDarkTheme');
    this.isDarkTheme = (darkThemeCurrentState != null && darkThemeCurrentState != undefined && darkThemeCurrentState != '' && JSON.parse(darkThemeCurrentState).isCheckedDarkTheme) ? true : false;
    this.commonService.getDarkThemeStatus().subscribe(isChecked => {
      this.isDarkTheme = isChecked;
    });
    this.leftMatchesBeforeLogin = JSON.parse(localStorage.getItem('leftMatchesBeforeLogin'));
   
    if(this.leftMatchesBeforeLogin==null){
      this.commonService.setShowLoginDashboardStatus(false);
    }
    else{
      leftmatchesBeforeLogin(this.leftMatchesBeforeLogin, this.leftMatchesBeforeLogin.appBetID);
      this.commonService.setShowLoginDashboardStatus(true);
    }
   this.getCasinoOpenStatus = this.commonService.getCasinoOpenStatus().subscribe((isChecked) => {
    this.isCasinoGameOpen = isChecked;
    });
    this.commonService._allgamesselectedStatus.subscribe((value) => {
    if(value == true){
      this.selecteitem = 0;
    }
    });
    this.commonService.selectedProviderStatus$().subscribe((value) => {
      if(value == true){
        this.casinoProviderItems.fill(false);
        this.casinoProviderItems[0] = true;
        this.selectprovider = 0;
      }
    });
    
  }

  ngOnChanges() {
    if (this.markets && this.markets.length > 0) {
    this.initCollapsible();
    this.setCasinoSettings();
    }
  }
  ngAfterViewInit() {
    this.initCollapsible();
  }
  mouseEvent(index, value) {
    if( this.selectprovider !== index)
      this.casinoProviderItems[index] = value;
  }
  initCollapsible() {
    var elcollapsible = document.querySelectorAll(".collapsible");
    var icollapsible = M.Collapsible.init(elcollapsible, {});
  }
  changeStyle(params: string, $event) {
    if($event.type == 'mouseover'){
      this.activeTabs(params);
    } else {
      this.activeTabs(this.selectedTab);
    }
  }
  activeTabs(params: string) {
    if (params === 'allsports') {
      this.activeAllSportsTab = true;
      this.activeInPlayTab = false;
      this.activeUpcomingTab = false;
    } else if (params === 'inplay') {
      this.activeAllSportsTab = false;
      this.activeInPlayTab = true;
      this.activeUpcomingTab = false;
    } else if (params === 'upcomingevent') {
      this.activeAllSportsTab = false;
      this.activeInPlayTab = false;
      this.activeUpcomingTab = true;
    }
    this.initCollapsible();
  }
  onTabMenuClick(selectedtab: string) {
    this.selectedTab = selectedtab;
    this.initCollapsible();
  }
  searchMatch(event, searchStr){
  this.commonService.searchget(searchStr);
  var key =  searchStr.indexOf(' ') >= 0;
  if(key != true && searchStr != ''){
    this.closesearch = true;
   }
  if(event.keyCode == 8 && searchStr == ''){
    this.closesearch = false;
  }

  }
  closeSearchModal(){
    this.searchStr = '';
    this.commonService.searchget(this.searchStr);
    this.closesearch = false;
  }
  Allgames(gamename, i){
    this.selecteitem = i;
  this.commonService.allgamesget(gamename);
  if (this.router.url !== '/live-casino') {
    localStorage.setItem('gamenameset', JSON.stringify(gamename));
    this.router.navigateByUrl('/live-casino');
    setTimeout(() => {
      this.selecteitem = i;
    }, 500);
  }    
}
  Allprovider(provider, i) {
    this.casinoProviderItems.fill(false);
    this.selectprovider = i;
    this.casinoProviderItems[i] = true;
  this.commonService.allprovidersget(provider);
    if (this.router.url !== '/live-casino') {
        localStorage.setItem('providerset', JSON.stringify(provider));
        this.router.navigateByUrl('/live-casino');
        setTimeout(() => {
          this.casinoProviderItems.fill(false);
          this.selectprovider = i;
          this.casinoProviderItems[i] = true;
        }, 500);
        // ,{state: {name: provider}} 
      }  
}
  getConfig() {
    this.authService
      .getConfig$()
      .pipe(
        untilDestroyed(this),
        catchError((err) => throwError(err))
      )
      .subscribe(
        (response) => {
          if (response) {
            this.commonService.configData = response;

          }
        },
        (err) => console.log("getConfig", err)
      );
  }
  isActiveExcludeSports(name) {
    return !!this.excludeSportsMarket.find((x) => x.st === name);
  }
  trackByFn(index: number, item: any) {  
    return index;  
  }
  getHRMarket(matchId) {
    return this.allMatches.filter(x => x.eid === matchId);
  }
  fnEventWise(index, item) {
    return item.appBetID;
  }
   
  getCasinoConfig() {
    this.authService.getCasinoConfig$()
      .pipe(
        untilDestroyed(this),
        catchError(err => throwError(err))
      ).subscribe(response => {
        if (response) {
          this.setCasinoSettings();
        }
      }, err => console.log('getConfig', err));
  }
  getGuestCasinoConfig() {
    this.authService.getGuestCasinoConfig$()
      .pipe(
        untilDestroyed(this),
        catchError(err => throwError(err))
      ).subscribe(response => {
        if (response) {
          this.setCasinoSettings();
        }
      }, err => console.log('getConfig', err));
  }
  setCasinoSettings() {
    let casinoGameList: any, ProviderList: any;
    this.user = JSON.parse(localStorage.getItem("token"));
    if (this.user == null || this.user == undefined || this.user == "") {
      this.casinoMenu = GuestCasinoConfig.data.casinoMenu ? GuestCasinoConfig.data.casinoMenu : [];
      casinoGameList = GuestCasinoConfig.data.casinoGameList ? GuestCasinoConfig.data.casinoGameList : [];
     ProviderList = GuestCasinoConfig.data.providerList ? GuestCasinoConfig.data.providerList : [];
     this.providerList = Object.assign([], ProviderList);
    } else {
     this.casinoMenu = casinoGameMenuSettings.data.casinoMenu ? casinoGameMenuSettings.data.casinoMenu : [];
     casinoGameList = casinoGameMenuSettings.data.casinoGameList ? casinoGameMenuSettings.data.casinoGameList : (apiEndPointData.data.casinoGameList ? apiEndPointData.data.casinoGameList : []);
     ProviderList = casinoGameMenuSettings.data.providerList ? casinoGameMenuSettings.data.providerList : (apiEndPointData.data.providerList ? apiEndPointData.data.providerList : []);
     this.websitesettings = websiteSettings.data.tpGamePermission ? websiteSettings.data.tpGamePermission : [];
     if(this.websitesettings.length > 0){
      this.websitesettings.forEach(element => {
        ProviderList = Object.assign([], ProviderList.filter(x=> x.appTpGameRefType !== element));
        this.providerList = Object.assign([], ProviderList);
      });
     } else {
      this.providerList = Object.assign([], ProviderList);
     }
    }
    this.casinoGameList = Object.assign([], casinoGameList.filter(x=> x.appIsActive));
    this.casinoGameList.unshift(this.All);  
    this.providerList.sort(this.GetSortOrder('appProviderName'));
    this.providerList.unshift(this.Provider);
    this.casinoProviderItems = new Array(this.providerList.length);
    this.casinoProviderItems.fill(false);
    this.casinoProviderItems[0] = true;
}

GetSortOrder(prop) {
  return function (a, b) {
    let key1 = a[prop];
    let key2 = b[prop];
    if (prop === 'appProviderName') {
      key1 = a[prop].toUpperCase();
      key2 = b[prop].toUpperCase();
    } 
    if (key1 > key2) {
      return 1;
    } else if (key1 < key2) {
      return -1;
    }
    return 0;
  };
}
  ngOnDestroy() {
    this.getCasinoOpenStatus.unsubscribe();
  }
}

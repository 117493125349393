
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { apiEndPointData } from '../config/connfig.service';
import { MarketSerializer } from '@clientApp-core/serializers/market/market.serializer';
import { ActiveMarket } from '@clientApp-core/models/market/activemarket.model';


@Injectable()
export class MarketFacadeService {

    private _marketSubject = new Subject<ActiveMarket[]>();
    private _removeMarketSubject = new Subject<any>();
    private _unpinMarketSubject = new Subject<any>();
    private _setVideoSubject = new Subject<any>();
    private _setVideoSubject1 = new Subject<any>();
    private _removeBetFromBetListSubject = new Subject<any>();
    marketList: ActiveMarket[] = [];

    constructor(private httpClient: HttpClient) {}

    getMarkets$(): Observable<ActiveMarket[]> {
        return this._marketSubject.asObservable();
    }
    getMarketToRemove$(): Observable<any> {
        return this._removeMarketSubject.asObservable();
    }
    removeBetFromBetList(): Observable<any> {
        return this._removeBetFromBetListSubject.asObservable();
    }
    removeMaket(id: any) {
        const obj = { BetId: id, MatchId: null };
        this._removeBetFromBetListSubject.next(obj);
        return this._removeMarketSubject.next(id);
    }
    getVideo$(): Observable<any> {
      return this._setVideoSubject.asObservable();
    }
    setVideo(id: any) {
        return this._setVideoSubject.next(id);
    }
    getVideoByUrl$(): Observable<any> {
      return this._setVideoSubject1.asObservable();
    }
    setVideoByUrl(id: any) {
      return this._setVideoSubject1.next(id);
    }
    getMarketToUnpin$(): Observable<any> {
      return this._unpinMarketSubject.asObservable();
    }
    unpinMaket(id: any) {
        const obj = { BetId: null, MatchId: id };
        this._removeBetFromBetListSubject.next(obj);
        return this._unpinMarketSubject.next(id);
    }
    getBaseUrl(item: any): string {
        let baseUrl: string;
        if (item.baseUrl) {
          baseUrl = `${item.baseUrl}${item.endPoint}`;
        } else {
          baseUrl = `${apiEndPointData.data.cau}${item.endPoint}`;
        }
        return baseUrl;
      }
}



<div id="change-password" class="modal modal-fixed-footer" #changePassw>
    <div class="modal-content">
        <a href="javascript:void(0)" (click)="reset('close')" class="modal-close right"><i
                class="fa fa-times" aria-hidden="true"></i></a>
        <div class="change-password-content">
            <h5>{{isResetPassword ? 'Reset Password': 'Change Password'}}</h5>
            <ng-container *ngIf="changePassword">
                <form class="col s12 p-0" [formGroup]="changePassword" novalidate (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="input-field col s12" *ngIf="changePassword.get('oldPassword') as oldPwdCtrl">
                            <input placeholder="Enter Old Password" type="password" class="validate"
                                formControlName="oldPassword" (keyup)="compairOldNew()"
                                [ngClass]="{'invalid': oldPwdCtrl.invalid && oldPwdCtrl.dirty}">
                            <label>Old Password</label>
                            <div *ngIf="oldPwdCtrl.errors && (oldPwdCtrl.dirty || oldPwdCtrl.touched)">
                                <span class="helper-text error" data-error="wrong" data-success="right"
                                    *ngIf="oldPwdCtrl.errors.required">Old Password is required.</span>
                                <span class="helper-text error" data-error="wrong" data-success="right"
                                    *ngIf="oldPwdCtrl.errors.minlength">Password must be 6 characters long.</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s12" *ngIf="changePassword.get('password') as newPwdCtrl">
                            <input placeholder="Enter New Password" type="password" class="validate"
                                (keyup)="compairNewConfirm()" formControlName="password"
                                [ngClass]="{'invalid': newPwdCtrl.invalid && newPwdCtrl.dirty}">
                            <label>New Password</label>
                            <div *ngIf="newPwdCtrl.errors && (newPwdCtrl.dirty || newPwdCtrl.touched)">
                                <span *ngIf="newPwdCtrl.errors.pattern" class="helper-text error" data-error="wrong"
                                    data-success="right">Password not match our format</span>
                                <span *ngIf="newPwdCtrl.errors.required" class="helper-text error" data-error="wrong"
                                    data-success="right">New Password is required.</span>
                            </div>
                            <span *ngIf="isPasswordSameToOld" class="helper-text error">New password not same as old password.</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s12"
                            *ngIf="changePassword.get('confirmPassword') as confirmPwdCtrl">
                            <input placeholder="Enter Confirm Password" type="password" class="validate"
                                (keyup)="compairNewConfirm()" formControlName="confirmPassword"
                                [ngClass]="{'invalid': confirmPwdCtrl.invalid && confirmPwdCtrl.dirty}">
                            <label>Confirm Password</label>
                            <div *ngIf="confirmPwdCtrl.errors && (confirmPwdCtrl.dirty || confirmPwdCtrl.touched)"
                                class="error">
                                <div *ngIf="confirmPwdCtrl.errors.required">
                                    <span class="helper-text error" data-error="wrong" data-success="right">Confirm
                                        Password is required.</span>
                                </div>
                            </div>
                            <span *ngIf="!isPasswordSame" class="helper-text error">
                                Confirm password must be same as new password.</span>
                        </div>
                    </div>
                </form>
            </ng-container>
        </div>
    </div>
    <div class="modal-footer">
        <div class="row submit-btn">
            <a *ngIf="isResetPassword" href="javascript:void(0)" (click)="reset('close')" class=" red btn"><span class="animate-btn">Close</span></a>
            <a *ngIf="!isResetPassword" href="javascript:void(0)" (click)="reset('')" class=" red btn"><span class="animate-btn">Clear</span></a>
            <a href="javascript:void(0)" (click)="onSubmit()"
                class=" green btn mr-10"><span class="animate-btn">Change</span></a>
        </div>
    </div>
</div>
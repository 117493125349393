import { Component, OnInit } from '@angular/core';
import { SharedModule } from '@clientApp-shared/shared.module';

@Component({
  selector: 'pb-loading-bar',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.css']
})
export class   LoadingBarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

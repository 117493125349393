import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { MarketBet } from '@clientApp-core/models/market/market-bet.model';
import { CookieService } from 'ngx-cookie-service';
import { take } from 'rxjs/operators';
import { apiEndPointData } from '../config/connfig.service';
import { MarketRates } from '@clientApp-core/models/market/market-rates.model';
import { MarketRunner } from '@clientApp-core/models/market/market-runner.model';

@Injectable({ providedIn: 'root' })
export class CommonService {

  private _loadingStatus = new Subject<any>();
  private _setSelectedTab = new Subject<any>();
  private _isMatchesFilter = new Subject<any>();
  private _oneClickStakeStatus = new Subject<any>();
  private _oneClickBetStatus = new Subject<boolean>();
  private _darkThemeStatus = new Subject<boolean>();
  private _inPlayStatus = new Subject<boolean>();
  _allgamesselectedStatus = new Subject<boolean>();
  private _selectedProviderStatus = new Subject<boolean>();
  private _isShowLoginDashboard = new Subject<boolean>();
  private _casinogamesopen = new Subject<boolean>();
  private _CasinoOpen = new Subject<boolean>();
  _searchSubject = new Subject<any>();
  _allgamesSubject = new Subject<any>();
  _allproviderSubject = new Subject<any>();



  centralizationIds: string;
  inPlayStatus = false;
  public sessionPostion: any = [];
  configData: any;
  marketClientBets: MarketBet[] = [];
  allMarkets = []; 
  selectedTab = 'Home';
  selectedRecingGame = '';
  isMarketLiabilityClick: boolean;
  isShowLiveTvCenter = false;
  ipAddress: string = '';
  chipList: any = [];
  isNewsExits: boolean = false;
  isCheckAcceptAnyOdds: boolean = false;
  curMarketsVol: MarketRates[] = [];
  curMarketsRunners: MarketRunner[] = [];
  FavoriteIds: any = [];
  constructor(private cookieService: CookieService) { }
  
  setCookieValue(name: string, value: string,) {
    const domain = window.location.hostname;
    this.cookieService.set(name, value, 365,'/',domain ? domain : '',false,'Lax');
  }
  getCookieValue(name: string) {
    return this.cookieService.get(name);
  }
  getCasinoOpenStatus(): Observable<boolean> {
      return this._casinogamesopen.asObservable();
   }
   setCasinoOpenStatus(status: boolean) {
    //  console.log(status);
       this._casinogamesopen.next(status);
   }
  deleteCookieValue(name: string) {
    return this.cookieService.delete(name);
  }
  getOneClickStakeStatus(): Observable<string> {
    return this._oneClickStakeStatus.asObservable();
  }
  setOneClickStakeStatus(stake: string) {
    this._oneClickStakeStatus.next(stake);
  }
  getOneClickBetStatus(): Observable<boolean> {
    return this._oneClickBetStatus.asObservable();
  }
  setOneClickBetStatus(isChecked: boolean) {
    this._oneClickBetStatus.next(isChecked);
  }
  getDarkThemeStatus(): Observable<boolean> {
    return this._darkThemeStatus.asObservable();
  }
  setDarkThemeStatus(isChecked: boolean) {
    this._darkThemeStatus.next(isChecked);
  }
  getLoadingStatus(): Observable<boolean> {
    return this._loadingStatus.asObservable();
  }
  setLoadingStatus(status: boolean) {
    this._loadingStatus.next(status);
  }
  getInPlayStatus(): Observable<boolean> {
    return this._inPlayStatus.asObservable();
  }
  setInPlayStatus(status: boolean) {
    this._inPlayStatus.next(status);
  }
  getShowLoginDashboardStatus(): Observable<boolean> {
    return this._isShowLoginDashboard.asObservable();
  }
  setShowLoginDashboardStatus(status: boolean) {
    this._isShowLoginDashboard.next(status);
  }
  getCategorieWiseMatches(): Observable<any> {
    return this._isMatchesFilter.asObservable();
  }
  setLoadingCategoriWiseMatches(showMatch: any) {
    this._isMatchesFilter.next(showMatch);
  }
  getSelectedTab(): Observable<any> {
    return this._setSelectedTab.asObservable();
  }
  setSelectedTab(selectedTab: any) {
    this._setSelectedTab.next(selectedTab);
  }
  imgRelativePath(path: string): string {
    return apiEndPointData.data.themeBasePath + path;
  }
  contentRelativePath(path: string): string {
    return apiEndPointData.data.commonContentPath + path;
  } 
  search$(): Observable<string> {
     return this._searchSubject.asObservable();
 }
  searchget(status: string) {
      this._searchSubject.next(status);
  }
  allgames$(): Observable<string> {
    return this._allgamesSubject.asObservable();
  }
  allgamesget(status: string) {
      this._allgamesSubject.next(status);
  }
  allgamesselected$(): Observable<boolean> {
    return this._allgamesselectedStatus.asObservable();
  }
  allgamesselected(status: boolean) {
    this._allgamesselectedStatus.next(status);
  }
  selectedProviderStatus$(): Observable<boolean> {
    return this._selectedProviderStatus.asObservable();
  }
  selectedProviderStatus(status: boolean) {
    this._selectedProviderStatus.next(status);
  }

  allprovider$(): Observable<string> {
    return this._allproviderSubject.asObservable();
  }
  allprovidersget(status: string) {
      this._allproviderSubject.next(status);
  }
  getGameOpenStatus(): Observable<boolean> {
    return this._CasinoOpen.asObservable();
 }
 setGameOpenStatus(status: boolean) {
     this._CasinoOpen.next(status);
 }
}



import { Routes } from '@angular/router';
import { LoginLayoutComponent } from './features/layouts/login-layout/login-layout.component';
import { HomeLayoutComponent } from './features/layouts/home-layout/home-layout.component';
import { LoggedInUserGuard } from '@clientApp-core/gaurds/logged-in-user.guard';
import { Isb2cuserGuard } from '@clientApp-core/gaurds/isb2cuser.guard';

export const routes: Routes = [ 
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [LoggedInUserGuard],
    children: [
      { path: '', redirectTo: 'live-casino', pathMatch: 'full' },
      {
        path: 'casinoGame/:id',
        loadChildren: () => import('./features/casino-games/casino-games.module').then(m => m.CasinoGamesModule)
      },
      {
        path: 'live-casino',
        loadChildren: () => import('./features/casino-games-list/casino-games-list.module').then(m => m.CasinoGamesListModule)
      },
      {
        path: 'account-statement',
        loadChildren: () => import('./features/account-statement/account-statement.module').then(m => m.AccountStatementModule)
      },
      // {
      //   path: 'old-account-statement',
      //   loadChildren: () => import('./features/old-account-statement/account-statement.module').then(m => m.OldAccountStatementModule)
      // },
      {
        path: 'transaction-history',
        canActivate: [Isb2cuserGuard],
        loadChildren: () => import('./features/transaction-history/transaction-history.module').then(m => m.TransactionHistoryModule)
      }
    ]
  },
    {
      path: '',
      component: LoginLayoutComponent,
      children: [
        { path: '', redirectTo: 'login', pathMatch: 'full' },
       
        {
          path: 'login',
          loadChildren: () => import('./features/dashboard-login/dashboard-login.module').then(m => m.DashboardLoginModule),
          data: {preload: true}
        }
      ]
    },
    {
      path: '**',
      redirectTo: 'login'
    }
  ];
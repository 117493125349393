import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, Injectable, Injector } from '@angular/core';
import { AppComponent } from './app.component';
import { ConfigService } from '@clientApp-core/services/config/connfig.service';
import { CookieService } from 'ngx-cookie-service';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '@clientApp-core/core.module';
import { SharedModule } from '@clientApp-shared/shared.module';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { routes } from './app.routing';
import { DatePipe } from '@angular/common';
import * as Hammer from 'hammerjs';
import { environment } from '../environments/environment';
import { CustomPreloadingStrategyService } from '@clientApp-core/services/custom/custom-preloading-strategy.service';
import { setAppInjector } from './app-injector';
import { HammerGestureConfig, BrowserModule, Title, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { LoadingBarComponent } from "./shared/components/loading-bar/loading-bar.component";
// export let AppInjector: Injector;
export function loadConfigurations(configService: ConfigService) {
  return () => configService.loadAppConfig();
}
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    'swipe': { direction: Hammer.DIRECTION_HORIZONTAL },
    'pinch': { enable: false },
    'rotate': { enable: false },
    'pan': { enable: false }
  };
}
@NgModule({
  declarations: [
    AppComponent
  ],
  providers: [CookieService, DatePipe, Title, CustomPreloadingStrategyService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [ConfigService],
      useFactory: loadConfigurations,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    }
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    RouterModule.forRoot(routes, {
      useHash: true, enableTracing: false, scrollPositionRestoration: 'enabled',
      preloadingStrategy: CustomPreloadingStrategyService
    }),
    LoadingBarComponent
  ]
})
export class AppModule {
  constructor(injector: Injector) {
    // AppInjector = this.injector;
    setAppInjector(injector);
  }
}

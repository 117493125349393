<body class="bg-img" [style.background-image]="'url('+bgImageVariable+')'" [class.dark]="isDarkTheme" *ngIf="!inMaintenance">
  <pb-header/>
  <!-- <main class="full-wrap"> -->
    <router-outlet/>
  <!-- </main> -->
  <div class="bottom-tabs" id="home-tabs">
    <ul>
      <li class="truncate"><a (click)="onMenuClick('Home')" [class.active] = "selectedTab == 'Home'">
     
        <img ngSrc="https://img.icons8.com/external-flatart-icons-outline-flatarticons/64/000000/external-favorite-user-interface-flatart-icons-outline-flatarticons.png" height="18" width="18" priority/>
        <!-- <img src="https://img.icons8.com/external-xnimrodx-lineal-gradient-xnimrodx/64/000000/external-favorite-real-estate-xnimrodx-lineal-gradient-xnimrodx.png"/> -->
        Favourite</a></li>
      <li class="truncate"><a >
        <span class="toggle-switch menu-item items">
        <input type="checkbox" id="switch-mobile" [(ngModel)]="isCheckedDarkTheme"
              (ngModelChange)="onChangeDarkTheme($event)">
              <div class="switch-app">
        
                <div class="content">
          
                  <label for="switch-mobile">
                    <div class="toggle"></div>
                    <div class="names">
                      <p class="light">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.24 25.178" width="25.24" height="25.178">
                          <g id="Group_3" transform="translate(-1073.83 -675.943)" data-name="Group 3">
                            <path id="Path_1" fill="#ff9b09" transform="translate(-45.888 400.063) rotate(3)"
                              d="M 1141.64 223.276 l -3.852 -3.944 l -1.547 1.393 l 4.01 4.025 Z" data-name="Path 1" />
                            <path id="Path_2" fill="#ff9b09" transform="translate(-168.174 500.976)"
                              d="M 1253.96 180.713 l 0.032 -5.746 h 1.968 v 5.746 Z" data-name="Path 2" />
                            <path id="Path_3" fill="#ff9b09" transform="translate(-218.06 390.752) rotate(3)"
                              d="M 1322.36 223.379 l 3.844 -3.988 l 1.53 1.422 l -3.893 3.928 Z" data-name="Path 3" />
                            <path id="Path_4" fill="#ff9b09" transform="matrix(0.998 0.07 -0.07 0.998 -233.764 256.876)"
                              d="M 1353.91 337.163 h 5.756 v 1.966 h -5.756 Z" data-name="Path 4" />
                            <path id="Path_5" fill="#ff9b09" transform="translate(-206.814 219.648) rotate(3)"
                              d="M 1321.94 404.826 l 4.019 4.007 l -1.386 1.431 l -4.057 -3.965 Z" data-name="Path 5" />
                            <path id="Path_6" fill="#ff9b09" transform="matrix(0.995 0.105 -0.105 0.995 -116.358 129.195)"
                              d="M 1256.27 443.038 h -2.045 v -5.7 h 2.045 Z" data-name="Path 6" />
                            <path id="Path_7" fill="#ff9b09" transform="translate(-36.146 229.555) rotate(3)"
                              d="M 1135.53 407.825 l 4.144 -4 l 1.391 1.443 l -4.045 4.03 Z" data-name="Path 7" />
                            <path id="Path_8" fill="#ff9b09" transform="matrix(0.998 0.07 -0.07 0.998 8.164 274.816)"
                              d="M 1097.66 337 v 1.858 h -5.7 v -1.94 l 5.7 -0.012 Z" data-name="Path 8" />
                            <path id="Path_9" fill="#ff9b09" transform="translate(-117.404 400.953)"
                              d="M 1203.86 282.988 a 3.81 3.81 0 0 1 0.559 4.651 c -1.567 2.586 -5.076 0.971 -5.076 0.971 a 4.568 4.568 0 0 1 0.959 -3.983 A 4.231 4.231 0 0 1 1203.86 282.988 Z"
                              data-name="Path 9" />
                            <path id="Path_10" fill="#ff9b09" transform="translate(-127.572 397.159)"
                              d="M 1210.21 293.825 a 5.35 5.35 0 0 0 2.72 0.085 a 4.853 4.853 0 0 0 2.659 -2.076 a 4.441 4.441 0 0 0 -0.061 -4.76 a 4.573 4.573 0 0 1 1.688 1.032 c 1.117 1.062 2.2 3.043 0.607 5.8 a 4.757 4.757 0 0 1 -5.9 1.53 A 3.356 3.356 0 0 1 1210.21 293.825 Z"
                              data-name="Path 10" />
                          </g>
                        </svg>
                      </p>
                      <p class="dark">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.492 20.213" width="20.492" height="20.213">
                          <g id="Group_5" transform="translate(-1048.44 -677.007)" data-name="Group 5">
                            <path id="Path_1" fill="#7397aa" transform="matrix(0.999 -0.035 0.035 0.999 -105.356 486.692)"
                              d="M 1152.5 236.076 l -2.836 -2.865 l -1.395 1.5 l 2.906 2.75 Z" data-name="Path 1" />
                            <path id="Path_2" fill="#7397aa" transform="translate(-196.846 502.039)"
                              d="M 1253.96 179.027 l 0.032 -4.06 h 1.962 v 4.06 Z" data-name="Path 2" />
                            <path id="Path_3" fill="#7397aa" transform="translate(-260.071 446.202)"
                              d="M 1321.85 235.486 l 2.922 -2.719 l 1.382 1.337 l -2.824 2.816 Z" data-name="Path 3" />
                            <path id="Path_4" fill="#7397aa" transform="translate(-276.49 302.27) rotate(2)"
                              d="M 1353.91 337.163 h 4.114 v 2.017 h -4.114 Z" data-name="Path 4" />
                            <path id="Path_5" fill="#7397aa" transform="translate(-278.116 356.904) rotate(-3)"
                              d="M 1322.23 403.9 l 2.734 2.891 l -1.347 1.306 l -2.828 -2.937 Z" data-name="Path 5" />
                            <path id="Path_6" fill="#7397aa" transform="translate(-172.051 190.723) rotate(3)"
                              d="M 1256.29 441.353 h -2.068 v -4.01 h 2.068 Z" data-name="Path 6" />
                            <path id="Path_7" fill="#7397aa" transform="translate(-97.844 287.169)"
                              d="M 1148.58 406.362 l 2.859 -2.672 l 1.375 1.391 l -2.891 2.75 Z" data-name="Path 7" />
                            <path id="Path_8" fill="#7397aa" transform="translate(-43.516 349.672)"
                              d="M 1096.03 336.995 v 1.847 h -4.067 v -1.929 l 4.067 -0.012 Z" data-name="Path 8" />
                            <path id="Path_9" fill="#7397aa" transform="translate(-145.675 399.078)"
                              d="M 1204.45 282.989 a 4.294 4.294 0 0 1 0.63 5.242 c -1.766 2.915 -5.721 1.095 -5.721 1.095 a 5.148 5.148 0 0 1 1.081 -4.489 A 4.769 4.769 0 0 1 1204.45 282.989 Z"
                              data-name="Path 9" />
                            <path id="Path_10" fill="#7397aa" transform="translate(-155.716 395.389)"
                              d="M 1210.21 294.473 a 5.864 5.864 0 0 0 2.981 0.093 a 5.32 5.32 0 0 0 2.915 -2.276 a 4.867 4.867 0 0 0 -0.066 -5.217 a 5.013 5.013 0 0 1 1.85 1.131 c 1.224 1.164 2.413 3.336 0.665 6.362 a 5.214 5.214 0 0 1 -6.469 1.677 A 3.678 3.678 0 0 1 1210.21 294.473 Z"
                              data-name="Path 10" />
                          </g>
                        </svg>
                      </p>
                    </div>
                  </label>
                </div>
          
              </div>
              </span>
        Theme</a></li>
        <li class="truncate"><a (click)="onMenuClick('Home')" [class.active] = "selectedTab == 'Home'">
          <svg xmlns="http://www.w3.org/2000/svg" width="18.372" height="17.153" viewBox="0 0 18.372 17.153">
            <g id="Group_396" data-name="Group 396" transform="translate(-0.045 0.003)">
              <g id="Group_395" data-name="Group 395">
                <path id="Path_185" data-name="Path 185" d="M18.11,24.8l-2.791-2.791V18.781a1.056,1.056,0,0,0-2.111,0v1.113L11.13,17.815a2.777,2.777,0,0,0-3.84,0L.31,24.8A1.056,1.056,0,0,0,1.8,26.291l6.979-6.98a.623.623,0,0,1,.854,0l6.98,6.98a1.056,1.056,0,0,0,1.5-1.491Z" transform="translate(-0.001 -17.047)" fill="#333"/>
                <path id="Path_186" data-name="Path 186" d="M75.652,127.9a.518.518,0,0,0-.733,0l-6.139,6.138a.52.52,0,0,0-.152.367v4.477a1.9,1.9,0,0,0,1.9,1.9h3.04v-4.707H77v4.707h3.04a1.9,1.9,0,0,0,1.9-1.9V134.4a.518.518,0,0,0-.152-.367Z" transform="translate(-66.075 -123.632)" fill="#333"/>
              </g>
            </g>
          </svg>
          Home</a></li>
        
      <li class="truncate"><a >
        <span class="menu-item items" (click)="openSearchModal()">
          <a href="javascript:void(0);">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 14.144 14.422">
              <path id="Path_188" data-name="Path 188"
                d="M9.963,8.561A5.424,5.424,0,1,0,8.294,10.1l4,3.976a1.136,1.136,0,1,0,1.647-1.564l-.041-.041Zm-4.431.371A3.5,3.5,0,1,1,8.009,7.905,3.5,3.5,0,0,1,5.532,8.932Zm0,0"
                transform="translate(-0.109 -0.007)" />
            </svg>
            <!-- <span>Search</span> -->
          </a>
        </span>
        <span>Search</span></a></li>
      <li class="truncate"><a class="dropdown-trigger1" #toggleButton data-target="dropdown2" [ngClass]="{'active': selectedTab == 'account'}" (click)="toggleMenu()">
        <svg #toggleImg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16">
          <path id="ic_person_24px" d="M12,12A4,4,0,1,0,8,8,4,4,0,0,0,12,12Zm0,2c-2.67,0-8,1.34-8,4v2H20V18C20,15.34,14.67,14,12,14Z" transform="translate(-4 -4)"/>
        </svg>
        <span #toggleSpan class="username">{{user.clientName}}</span></a></li>
    </ul>
    <ul id='dropdown2' [class.is-show]="isMenuOpen" #bottomMenu class='dropdown-content animated animatedFadeInUp fadeInUp is-hidden' >
      <li><a><i class="fas fa-user"></i> Welcome, {{user.clientName}}</a></li>
      <li><a (click)="redirectTo('/account-statement')"> <i class="fas fa-chart-area"></i> Account Statement</a></li>
      <li *ngIf="isB2C"><a (click)="onDepositWithdrawClick()"> <i class="fas fa-dollar-sign"></i> Deposit/Withdraw</a></li>
      <li *ngIf="isB2C"><a (click)="redirectTo('/transaction-history')"> <i class="fas fa-history"></i> Transaction History</a></li>
    
      <li><a (click)="onChangePassClick()" ><i class="fas fa-key"></i> Change Password</a></li>
      <li class="log-out"><a (click)="onLogOut()"><i class="fas fa-sign-out-alt"></i> Sign Out</a></li>
      <li><a (click)="onCloseBtnClick()" href="javascript:void(0)" class="close-btn"><i class="fas fa-times"></i></a></li>
    </ul>
  </div>
  <div class="custom-sidenav"></div>
    <!-- <app-footer></app-footer> --> 
</body>
<pb-loading-bar *ngIf="loading"/>
<pb-audio-player #player/>
<pb-rules #rules [name]="'Terms and Condition'"/>
<app-change-password #changePass/>
<app-search #search [searchString]="" (valueChange)='searchValueChange($event)'/>
<app-deposit-withdraw  #depositwithdraw/>
<app-maintenance *ngIf="inMaintenance"/>

<div id="casino-nav">
  <ul class="sidenav scroller " id="mobile-nav"   [class.sidenav-height]="!isNewsExits" [class.sidenav-news-height]="isNewsExits">   
    <div class="casino-search-box" *ngIf="isCasinoGameOpen">      
      <form class="col s12 " *ngIf="casinoMenu && casinoMenu.length > 0">
        <div class="row p-0 m-0">
          <div class="input-field col s12">
            <i class="fa fa-search"></i>
            <input placeholder="Search.." type="text" class="validate z-depth-0" name="search"
            [(ngModel)]="searchStr" (keyup)="searchMatch($event, searchStr)" autocomplete="off">
            <i *ngIf="closesearch" (click)="closeSearchModal()" class="fas fa-times"></i>
          </div>
        </div>
      </form>  
    </div>    
    <div class="casino-search" *ngIf="isCasinoGameOpen">
      <div class="all-games" *ngIf="casinoGameList && casinoGameList.length > 1">
        <h3 class="category-title">Games</h3>
        <div class="category-collection">
        <ng-container *ngFor="let casioGame of casinoGameList; let i= index;let even = even;trackBy:trackByFn">
          <span class="item-collection" *ngIf="even">
            <a *ngIf="casioGame.appIsActive" class="title" [ngClass]="{'active': selecteitem == i}" (click)="Allgames(casioGame.appGameName, i);"> 
              <!-- <img [src]="casioGame.appIcon" class="responsive-img"> -->
              <img [ngSrc]="casioGame.appIcon" height="25" width="25" alt="" priority  class="responsive-img"> {{casioGame.appDisplayName}}
            </a>         
            <a *ngIf="casinoGameList[i+1]" class="title" [ngClass]="{'active': selecteitem == i+1}" (click)="Allgames(casinoGameList[i+1]['appGameName'], i+1);"> 
              <!-- <img [src]="casinoGameList[i+1]['appIcon']" class="responsive-img">  -->
              <img [ngSrc]="casinoGameList[i+1]['appIcon']" height="25" width="25" alt="" priority  class="responsive-img"> {{casinoGameList[i+1]['appDisplayName']}}</a>
          </span>
        </ng-container>
        </div>
      </div>    
      <div class="all-providers"  *ngIf="providerList && providerList.length > 1">
        <h3 class="provider-title">Providers</h3>
          <div class="provider-collection">
  
            <span class="provider-list" *ngFor="let provider of providerList; let i= index;trackBy:trackByFn" (click)="Allprovider(provider.appProviderName, i);">
              <div *ngIf="provider.appIsActive">
              <a class="providerclass" [ngClass]="{'active': selectprovider == i}" *ngIf="provider.appProviderLogo != null">
                 <!-- <img [src]="casinoProviderItems[i] ?  provider.appProviderLogo : provider.appDarkLogo" (mouseout)="mouseEvent(i,false)" (mouseover)="mouseEvent(i,true)" class="responsive-img"> -->
                 <img [ngSrc]="casinoProviderItems[i] ?  provider.appProviderLogo : provider.appDarkLogo" (mouseout)="mouseEvent(i,false)" (mouseover)="mouseEvent(i,true)" height="300" width="480" style="height: auto; width: auto;" alt="" priority  class="responsive-img">
                </a>
              </div>
            </span>           
          </div>  
      </div>
    </div>
  </ul>
</div>

<div id="footer-main">
<div class="footer">
  <div class="links">
    <ul>
      <li>
        <a href="javascript:void(0)"> About us </a>
      </li>
      <li>
        <a href="javascript:void(0)"> Help </a>
      </li>
      <li>
        <a href="javascript:void(0)"> Security </a>
      </li>
      <li>
        <a href="javascript:void(0)"> Game Rules </a>
      </li>
      <li>
        <a href="javascript:void(0)"> Terms and Conditions </a>
      </li>
      <li>
        <a href="javascript:void(0)"> Responsible Gambling </a>
      </li>
    </ul>
  </div>
  <div class="icons">
    <a href="javascript:void(0)">
      <!-- <img [src]="commonService.contentRelativePath('assets/images/18plus.png?v=1')" loading="lazy"> -->
      <img [ngSrc]="commonService.contentRelativePath('assets/images/18plus.png?v=1')" height="50" width="50" style="height: auto; width: auto;" alt="" priority >
    </a>
    <a href="javascript:void(0)">
      <!-- <img [src]="commonService.contentRelativePath('assets/images/gamecare.png?v=1')" loading="lazy"> -->
      <img [ngSrc]="commonService.contentRelativePath('assets/images/gamecare.png?v=1')" height="50" width="50" style="height: auto; width: auto;" alt="" priority >

    </a>
    <a href="javascript:void(0)">
      <!-- <img [src]="commonService.contentRelativePath('assets/images/gt.png?v=1')" loading="lazy"> -->
      <img [ngSrc]="commonService.contentRelativePath('assets/images/gt.png?v=1')" height="50" width="50" style="height: auto; width: auto;" alt="" priority >

    </a>
  </div>
  <div class="browser-wrap">
    License info BLUE SAPPHIRE N.V. operates in Curacao, with a company Registration No: 149875, and registered address is Heelsumstraat 51, E- COMMERCE PARK, ORION WEG, 5C, WILLEMSTAD, CURACAO. The Company operates under the General Governor of Curacao Gaming License under the number 8048/JAZ2019-038
  </div>
  <div class="social-media">
    <a href="javascript:void(0)">
      <!-- <img class="responsive-img" [src]="commonService.contentRelativePath('assets/images/facebook.png')" loading="lazy"> -->
      <img [ngSrc]="commonService.contentRelativePath('assets/images/facebook.png')" height="30" width="30"  alt="" priority  class="responsive-img">

    </a>
    <a href="javascript:void(0)">
      <!-- <img class="responsive-img" [src]="commonService.contentRelativePath('assets/images/instagram.png')" loading="lazy"> -->
      <img [ngSrc]="commonService.contentRelativePath('assets/images/instagram.png')" height="30" width="30"  alt="" priority  class="responsive-img">

    </a>
    <a href="javascript:void(0)">
      <!-- <img class="responsive-img" [src]="commonService.contentRelativePath('assets/images/youtube.png')" loading="lazy"> -->
      <img [ngSrc]="commonService.contentRelativePath('assets/images/youtube.png')" height="30" width="30" alt="" priority  class="responsive-img">

    </a>
  </div>
  <div class="copyright">
    <a class="title">© Copyright {{currentYear}}, All Rights Reserved </a>
  </div>
  
</div>
</div>
<body class="bg-img maintenance">
    <!-- Header -->
    <header>
      <div class="navbar-fixed">
        <nav class="" id="global-nav">
          <div class="nav-wrapper valign-wrapper top-header">
            <a href="javascript:void(0)" class="brand-logo">
                <!-- <img src="images/logo-dark.png" alt="cric99" class="responsive-img" /> -->
            </a>
          </div>
          <!-- top-header over here -->
        </nav>
      </div>   <!-- nabar-fixed over here -->
  
     
    </header>
    <!-- Middle Content -->
    <main class="full-wrap">
      <div class="row m-0">
        
        <div class="col s12 m6">
          <div class="mt-title">
            <h3>
              We are upgrading
            </h3>
            <p>We are busy upgrading {{websiteName}} with new features, please try again in some times.</p>
            <p>We apologize for the inconvenience and appriciate your patience.</p>
            <p>Thank you for using {{websiteOrigin}}</p>
          </div>
        </div>
        
        <div class="col s12 m6 center">
          <img [ngSrc]="maintenanceImage" height="200" width="200" style="height: auto; width: auto;" priority class="mt-img" />


        </div>
  
      </div>
      <app-footer/>
    </main>
    
  </body>